import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { FrequencyAssignmentEndpoints } from '../types/endpoint.type'
import {
  IFrequencyAssignment,
  IGetFrequencyAssignmentFilters,
} from '../types/exercise.type'
import { IClassificationType } from '../types/classifications.type'
import {
  IOperatingSubUnit,
  IOperatingUnit,
} from '../types/operating-unit.types'

export const getFrequencyAssignments = async ({
  endDate,
  filters,
  frequencyMax,
  frequencyMin,
  startDate,
}: IGetFrequencyAssignmentFilters): Promise<
  IResponse<IFrequencyAssignment[]>
> => {
  try {
    const params = new URLSearchParams({
      filters: JSON.stringify(filters),
      frequencyMin: String(frequencyMin),
      frequencyMax: String(frequencyMax),
    })

    if (startDate && endDate) {
      params.append('startDate', startDate)
      params.append('endDate', endDate)
    }

    const response = await axiosInstance.get(FrequencyAssignmentEndpoints.GET, {
      params,
    })

    return {
      data: response.data,
      message: '',
      success: true,
    }
  } catch (err: any) {
    console.error('Error making API request', err)
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
