import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Sidebar from '../../components/features/ExerciseRequestForm/Sidebar'
import Sections from '../../components/features/ExerciseRequestForm/Sections'
import { AppConfigContext } from '../../contexts/app-config.context'
import { ExerciseRequestContext } from '../../contexts/exercise-request.context'
import { ExerciseRequestEquipmentContext } from '../../contexts/exercise-request-equipment.context'

import styles from './styles.module.scss'

const ExerciseRequestFormPage = () => {
  const [value, setValue] = useState(0)

  const { isLightMode } = useContext(AppConfigContext)
  const {
    selectedExerciseRequest,
    setSelectedExerciseRequestFrequency,
    setSelectedExerciseRequestLocation,
  } = useContext(ExerciseRequestContext)
  const { setEquipment } = useContext(ExerciseRequestEquipmentContext)

  useEffect(() => {
    return () => {
      setEquipment(null)
      setSelectedExerciseRequestFrequency(null)
      setSelectedExerciseRequestLocation(null)
    }
  }, [selectedExerciseRequest])

  return (
    <Box className={`${styles.exerciseRequestForm} main-container`}>
      <Sidebar setValue={setValue} isLightMode={isLightMode} />
      <Sections value={value} setValue={setValue} isLightMode={isLightMode} />
    </Box>
  )
}

export default ExerciseRequestFormPage
