import { Dispatch, FC, SetStateAction, useContext } from 'react'
import { Box, Button } from '@mui/material'
import Search from '../../../shared/Search'
import { AppConfigContext } from '../../../../contexts/app-config.context'

import styles from './styles.module.scss'

interface IProps {
  btnText: string
  handleSearch: (search: string) => void
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const DashboardToolbar: FC<IProps> = ({ btnText, handleSearch, setIsOpen }) => {
  const { isLightMode } = useContext(AppConfigContext)
  return (
    <Box className={styles.toolbar}>
      <Search isLightMode={isLightMode} handleSearch={handleSearch} />
      <Button
        data-testid="button"
        id="openPlaybookCreateModal"
        className={styles.createBtn}
        onClick={() => setIsOpen(true)}
      >
        {btnText}
      </Button>
    </Box>
  )
}

export default DashboardToolbar
