import { useContext, useEffect, useState } from 'react'
import { Box } from '@mui/material'
import Loader from './components/shared/Loader'
import ErrorPage from './components/shared/ErrorPage'
import { useKeycloak } from '@react-keycloak/web'
import RoutesComponent from './routers'
import { configureAxiosAuth } from './services/axios.service'
import { HistoryRouter } from './utils/history'
import { getUserByEmail } from './services/auth.service'
import { AppConfigContext } from './contexts/app-config.context'
import { AuthContext } from './contexts/auth.context'

import './styles/global.scss'

const App = () => {
  const [errorMsg, setErrorMsg] = useState('')

  const { keycloak, initialized } = useKeycloak()
  const { isLightMode } = useContext(AppConfigContext)
  const { user: AuthUser, setUser } = useContext(AuthContext)

  const getUser = async () => {
    if (keycloak.token) {
      const data = await getUserByEmail(
        keycloak.tokenParsed.email,
        keycloak.token,
        keycloak.tokenParsed.preferred_username,
      )

      if (data.data) {
        setUser(data.data)
        setErrorMsg('')
      } else if (!data.success) {
        setErrorMsg(data.message)
      }
    }
  }

  useEffect(() => {
    getUser()
  }, [keycloak.tokenParsed])

  useEffect(() => {
    if (keycloak.token) {
      configureAxiosAuth(keycloak.token)
    }
  }, [keycloak.token])

  useEffect(() => {
    const rootElement = document.documentElement

    if (isLightMode) {
      rootElement.classList.add('light-mode')
      rootElement.classList.remove('dark-mode')
    } else {
      rootElement.classList.add('dark-mode')
      rootElement.classList.remove('light-mode')
    }
  }, [isLightMode])

  if (errorMsg) {
    return <ErrorPage code={400} message={errorMsg} />
  }

  if (!initialized || (keycloak.authenticated && !AuthUser)) {
    return <Loader />
  }

  return (
    <Box className="App">
      <HistoryRouter>
        <RoutesComponent />
      </HistoryRouter>
    </Box>
  )
}

export default App
