import configJson from './auth_config.json'

export function getConfig() {
  // Configure the audience here. By default, it will take whatever is in the config
  // (specified by the `audience` key) unless it's the default value of "YOUR_API_IDENTIFIER" (which
  // is what you get sometimes by using the Auth0 sample download tool from the quickstart page, if you
  // don't have an API).
  // If this resolves to `null`, the API page changes to show some helpful info about what to do
  // with the audience.
  const audience =
    configJson.audience && configJson.audience !== 'YOUR_API_IDENTIFIER'
      ? configJson.audience
      : null

  return {
    domain: configJson.domain,
    clientId: configJson.clientId,
    ...(audience ? { audience } : null),
  }
}

// In production we use window.config, set in public/index.html.
// If you change the config properties here,
// you should also change ../server.ts.
export const config =
  process.env.NODE_ENV === 'production'
    ? window.config
    : {
        baseUrl: process.env.REACT_APP_BASE_URL || 'http://localhost:3001',
        keycloakClientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID || 'spex',
        keycloakRealm: process.env.REACT_APP_KEYCLOAK_REALM || 'spex',
        keycloakUrl:
          process.env.REACT_APP_KEYCLOAK_URL || 'http://localhost:8080',
        hashidSecret: process.env.REACT_APP_HASHID_SECRET || 'abc',
      }
