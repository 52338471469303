import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { HeatmapEndpoints } from '../types/endpoint.type'
import { IHeatmapData, IHeatmapParams, IPathData } from '../types/cloudRF.type'
import { ICreateHeatmap } from '../types/heatmap.type'

export const createHeatmap = async (
  emitterData: ICreateHeatmap,
  heatmapParams: IHeatmapParams,
): Promise<IResponse<IHeatmapData>> => {
  try {
    const data = await axiosInstance.post(
      `${HeatmapEndpoints.CREATE_HEATMAP}`,
      { emitterData, heatmapParams },
    )

    return {
      success: true,
      data: data.data,
      message: 'OK',
    }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const createHeatmapSimulation = async (
  requestData: IHeatmapParams,
): Promise<IResponse<IHeatmapData>> => {
  try {
    const data = await axiosInstance.post(
      `${HeatmapEndpoints.CREATE_SIMULATION}`,
      requestData,
    )

    return {
      success: true,
      data: data.data,
      message: 'OK',
    }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const analyzePoints = async (
  requestData: IHeatmapParams,
): Promise<IResponse<IPathData>> => {
  try {
    const data = await axiosInstance.post(
      `${HeatmapEndpoints.ANALYZE_POINTS}`,
      requestData,
    )

    return {
      success: true,
      data: data.data,
      message: 'OK',
    }
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
