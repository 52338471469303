import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import Hashids from 'hashids'
import Paper from '@mui/material/Paper'
import {
  IconButton,
  Table,
  TableRow,
  TableBody,
  TableHead,
  TableCell,
  TableContainer,
  Box,
} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import StopIcon from '@mui/icons-material/Stop'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import VisibilityIcon from '@mui/icons-material/Visibility'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import Loader from '../../../shared/Loader'
import Pagination from '../../../shared/Pagination'
import PlaybookEditModal from '../../Modals/PlaybookUpdateModal'
import ConfirmationModal from '../../Modals/ConfirmationModal'
import PlaybookEnableModal from '../../Modals/PlaybookEnableModal'
import { useToast } from '../../../../contexts/toast.context'
import { PlaybookContext } from '../../../../contexts/playbooks.context'
import { deletePlaybook } from '../../../../services/playbook.service'
import { PlaybookColumns } from '../../../../utils/constants'
import {
  IPlaybook,
  PlaybookColumnsTypes,
} from '../../../../types/playbook.type'
import { AppConfigContext } from '../../../../contexts/app-config.context'

import styles from './styles.module.scss'

export const hashids = new Hashids(process.env.REACT_APP_HASHID_SECRET, 10)

const PlaybookTable = () => {
  const [modalType, setModalType] = useState('')
  const [selectedPlaybook, setSelectedPlaybook] = useState<
    IPlaybook | undefined
  >()

  const { playbooks, pagination, getPlaybooks, isLoading } =
    useContext(PlaybookContext)
  const { isLightMode } = useContext(AppConfigContext)

  const navigate = useNavigate()
  const { showToast } = useToast()

  const handleClick = (
    evt: React.MouseEvent,
    playbook: IPlaybook,
    type: 'edit' | 'run' | 'delete',
  ) => {
    evt.stopPropagation()
    setSelectedPlaybook(playbook)
    setModalType(type)
  }

  const handleDelete = async () => {
    if (selectedPlaybook) {
      const data = await deletePlaybook(selectedPlaybook.id)

      if (data.success) {
        handleClose()
        getPlaybooks()
        showToast('success', data.message)
      }
    }
  }

  const handleNavigate = (id: number) => {
    navigate(`/${hashids.encode(id)}`)
  }

  const handleClose = () => {
    setModalType('')
    setSelectedPlaybook(undefined)
  }

  const onPageChange = (page: number) => {
    pagination.skip = page
    getPlaybooks()
  }

  const onRowsPerPageChange = (rows: number) => {
    pagination.take = rows
    pagination.skip = 0
    getPlaybooks()
  }

  return (
    <Box className={styles.tableComponent}>
      <TableContainer className={styles.tableContainer} component={Paper}>
        <Table
          className={styles.table}
          style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              {PlaybookColumns.map((column) => (
                <TableCell
                  key={column}
                  style={{ color: isLightMode ? 'black' : 'white' }}
                  className={styles.bodyCell}
                  component="th"
                  scope="row"
                  align="left"
                >
                  {column}
                </TableCell>
              ))}
              <TableCell
                className={styles.bodyCell}
                style={{ color: isLightMode ? 'black' : 'white' }}
                component="th"
                scope="row"
                align="left"
              >
                actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody id="tableBody">
            {!isLoading && (
              <>
                {playbooks.map((data, ind) => (
                  <TableRow
                    id="tableRow"
                    key={`${data[PlaybookColumns[ind]]}` + ind + new Date()}
                    sx={{ padding: 20 }}
                    className={styles.bodyRow}
                  >
                    {PlaybookColumns.map((column) => (
                      <TableCell
                        key={column}
                        style={{ color: isLightMode ? 'black' : 'white' }}
                        className={styles.bodyCell}
                        component="th"
                        scope="row"
                        align="left"
                      >
                        {`${
                          column === PlaybookColumnsTypes.CREATED_AT
                            ? new Date(data[column]).toUTCString()
                            : data[column]
                        }`}
                      </TableCell>
                    ))}
                    <TableCell className={styles.actions}>
                      <IconButton
                        className={styles.action}
                        onClick={(evt) => handleClick(evt, data, 'run')}
                      >
                        {data.enabled ? (
                          <StopIcon
                            sx={{ color: 'red', width: 30, height: 30 }}
                          />
                        ) : (
                          <PlayArrowIcon
                            sx={{ color: 'green', width: 30, height: 30 }}
                          />
                        )}
                      </IconButton>
                      <IconButton
                        className={styles.action}
                        onClick={(evt) => handleClick(evt, data, 'edit')}
                      >
                        <EditIcon
                          sx={{
                            width: 30,
                            height: 30,
                            color: isLightMode ? 'gray' : '#828282',
                          }}
                        />
                      </IconButton>
                      <IconButton
                        id="deleteButton"
                        className={styles.action}
                        onClick={(evt) => handleClick(evt, data, 'delete')}
                      >
                        <DeleteOutlineOutlinedIcon
                          sx={{ color: '#f96666', width: 30, height: 30 }}
                        />
                      </IconButton>
                      <IconButton
                        className={styles.action}
                        id="playbookViewBtn"
                        onClick={() => handleNavigate(data.id)}
                      >
                        <VisibilityIcon
                          sx={{
                            width: 30,
                            height: 30,
                            color: isLightMode ? 'gray' : '#828282',
                          }}
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </>
            )}
            <TableRow>
              <Pagination
                isLightMode={isLightMode}
                pagination={pagination}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
              />
            </TableRow>
          </TableBody>
        </Table>
        {isLoading && (
          <Box>
            <Loader />
          </Box>
        )}
      </TableContainer>
      {modalType === 'delete' && (
        <ConfirmationModal
          text="Are you sure you want to delete playbook"
          btnText="Delete"
          color="error"
          onConfirm={handleDelete}
          onClose={handleClose}
        />
      )}
      {modalType === 'run' && (
        <PlaybookEnableModal
          isOpen={true}
          playbook={selectedPlaybook}
          onClose={handleClose}
        />
      )}
      {modalType === 'edit' && selectedPlaybook && (
        <PlaybookEditModal playbook={selectedPlaybook} onClose={handleClose} />
      )}
    </Box>
  )
}

export default PlaybookTable
