import { FC } from 'react'
import { Box, Typography } from '@mui/material'

import styles from './styles.module.scss'

interface IProps {
  code: number
  message: string
}

const ErrorPage: FC<IProps> = ({ code, message }) => {
  return (
    <Box className={`${styles.error} sub-container`}>
      <Box className={styles.errorContainer}>
        <Typography className={styles.code}>{code}</Typography>
        <Typography className={styles.border}>|</Typography>
        <Typography className={styles.message}>{message}</Typography>
      </Box>
    </Box>
  )
}

export default ErrorPage
