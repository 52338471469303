import { FC, useRef, useMemo, Dispatch, useEffect, SetStateAction } from 'react'
import { Box } from '@mui/material'
import { toast } from 'react-toastify'
import { ImageOverlay } from 'react-leaflet'
import { IHeatmapData } from '../../../types/cloudRF.type'
import { latLngBounds, LatLngBounds, LeafletEvent } from 'leaflet'
import { IPrepareHeatmapParams } from '../../../utils/prepareAreaRequestData'
import { IHeatmapLoadingStatus } from '../map-entity'

interface IProps {
  id: string
  type: 'heatmap' | 'simulation'
  heatmap?: IHeatmapData | null
  showHeatmap: boolean
  heatmapParams?: IPrepareHeatmapParams
  setHeatmapLoadingStatus: Dispatch<SetStateAction<IHeatmapLoadingStatus>>
  heatmapLoadingStatus: IHeatmapLoadingStatus
}

const HeatmapComponent: FC<IProps> = ({
  id,
  type,
  heatmap,
  showHeatmap,
  heatmapLoadingStatus,
  setHeatmapLoadingStatus,
}) => {
  const heatmapBounds = useMemo<LatLngBounds | null>(
    () =>
      heatmap?.bounds
        ? latLngBounds(
            [heatmap.bounds[0], heatmap.bounds[1]],
            [heatmap.bounds[2], heatmap.bounds[3]],
          )
        : null,
    [heatmap],
  )

  const timeoutHandles = useRef<number[]>([])

  /*
   * generateHandleHeatmapImageError - creates an error handler for either the heatmap or the simulation,
   * so that we can track the number of failures for each separately.
   */
  const generateHandleHeatmapImageError = (topic: 'heatmap' | 'simulation') => {
    const heatmapRetryDelayMilliseconds = 1000
    const maxHeatmapErrors = 5
    return (event: LeafletEvent) => {
      const failures = heatmapLoadingStatus[id][topic] + 1
      setHeatmapLoadingStatus((prev) => ({
        ...prev,
        [id]: { ...prev[id], [topic]: failures },
      }))
      // Unfortunately the onerror event doesn't include any information about why the image failed to load,
      // so just show something generic.
      console.warn(`Error loading ${id} ${topic}, attempt ${failures}`, event)
      if (failures > maxHeatmapErrors) {
        toast.error(`Error displaying ${id} ${topic}`)
        event.target._image.style.display = 'none'
        // Reset to zero failures, so that if the user Hides then Shows we will try again.
        setHeatmapLoadingStatus((prev) => ({
          ...prev,
          [id]: { ...prev[id], [topic]: 0 },
        }))
      } else {
        const timeoutHandle = window.setTimeout(() => {
          // Remove the handle. O(n) is fine here:
          const pos = timeoutHandles.current.indexOf(timeoutHandle)
          if (pos >= 0) timeoutHandles.current.splice(pos, 1)

          // Reload the image:
          event.target._image.src = event.target._image.src
        }, heatmapRetryDelayMilliseconds * failures)
        timeoutHandles.current.push(timeoutHandle)
      }
    }
  }

  /* generateHandleHeatmapImageLoad - clears the error count for the given heatmap. */
  const generateHandleHeatmapImageLoad = (topic: 'heatmap' | 'simulation') => {
    return () => {
      console.debug(`successfully loaded ${id} ${topic}`)
      setHeatmapLoadingStatus((prev) => ({
        ...prev,
        [id]: { ...prev[id], [topic]: 0 },
      }))
    }
  }

  useEffect(() => {
    return () => {
      while (timeoutHandles.current.length) {
        const handle = timeoutHandles.current.pop()
        clearTimeout(handle)
      }
    }
  }, [])

  return (
    <Box>
      {showHeatmap && heatmap && heatmap.PNG_Mercator && heatmapBounds && (
        <ImageOverlay
          url={`data:image/png;base64,${heatmap.PNG_Mercator}`}
          bounds={heatmapBounds}
          eventHandlers={{
            error: generateHandleHeatmapImageError(type),
            load: generateHandleHeatmapImageLoad(type),
          }}
        />
      )}
    </Box>
  )
}

export default HeatmapComponent
