import axios from 'axios'
import { config } from '../config'

export const axiosInstance = axios.create({
  baseURL: `${config.baseUrl}/api`,
})

export const configureAxiosAuth = async (token: string) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      try {
        config.headers.Authorization = `Bearer ${token}`
      } catch (error) {
        console.error('Error fetching access token:', error)
      }
      return config
    },
    (error) => {
      return Promise.reject(error)
    },
  )
}
