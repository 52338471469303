import { ExerciseRequestFormEndpoints } from '../types/endpoint.type'
import {
  IDevice,
  IStationClass,
  IEmissionType,
  IManufacturer,
  IDeviceAntenna,
  INumberOfStations,
  IRadarTunabilityTypes,
  IEquipmentNomenclature,
  IExerciseRequestEquipment,
  IExerciseRequestStationClass,
  IEmissionDesignatorSimpleType,
  IExerciseRequestDeviceAntenna,
} from '../types/equipment.type'
import { IResponse } from '../types/response.type'
import { axiosInstance } from './axios.service'

export const getAllEquipments = async (
  id: number,
): Promise<IResponse<IExerciseRequestEquipment[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_EQUIPMENTS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const createExerciseEquipment = async (
  id: number,
): Promise<IResponse<IExerciseRequestEquipment>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_EQUIPMENTS,
      {
        exerciseRequestId: id,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const updateEquipmentById = async (
  id: number,
  params: IExerciseRequestEquipment,
): Promise<IResponse<IExerciseRequestEquipment>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_EQUIPMENTS}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const deleteEquipmentById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_EQUIPMENTS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllDevices = async (): Promise<IResponse<IDevice[]>> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_DEVICES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllNumberOfStations = async (): Promise<
  IResponse<INumberOfStations[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_NUMBER_OF_STATIONS,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllNomenclatures = async (): Promise<
  IResponse<IEquipmentNomenclature[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_NOMENCLATURES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllManufacturers = async (): Promise<
  IResponse<IManufacturer[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_NOMENCLATURES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllRadarTunabilityTypes = async (): Promise<
  IResponse<IRadarTunabilityTypes[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.GET_RADAR_TUNABILITY_TYPES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
export const getAllStationClassDescriptions = async (): Promise<
  IResponse<IStationClass[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.STATION_CLASS_DESCRIPTION,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllEmissionDesignatorSimpleTypes = async (): Promise<
  IResponse<IEmissionDesignatorSimpleType[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.EMISSION_DESIGNATOR_SIMPLE_TYPES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllEmissionTypes = async (): Promise<
  IResponse<IEmissionType[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.EMISSION_TYPES,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllStationClasses = async (): Promise<
  IResponse<IStationClass[]>
> => {
  try {
    const data = await axiosInstance.get(
      ExerciseRequestFormEndpoints.STATION_CLASS,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllExerciseRequestStationClasses = async (
  id: number,
): Promise<IResponse<IExerciseRequestStationClass[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_STATION_CLASS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const createExerciseRequestStationClass = async (
  id: number,
): Promise<IResponse<IExerciseRequestStationClass>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_STATION_CLASS,
      {
        deviceId: id,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const updateExerciseRequestStationClassById = async (
  id: number,
  params: {
    station_class_id: number
    station_class_type: string
    bandwidth_from: number
    bandwidth_from_frequency_id: number
    bandwidth_to: number
    bandwidth_to_frequency_id: number
    power: number
    power_range_id: number | null
    flight_level: number
    modulation_id: number
    traffic_id: number
    is_traffic_encrypted: boolean
    antenna_radius: number
    antenna_feed_point_height: number
    emission_type_id: number
    operating_unit_id: number
    operating_unit_other: string
    emission_designator_simple_type_id: number
    emission_designator_id: number
  },
): Promise<IResponse<IExerciseRequestStationClass>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_STATION_CLASS}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const deleteExerciseRequestStationClassById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_STATION_CLASS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllExerciseRequestDeviceAntennas = async (
  id: number,
): Promise<IResponse<IExerciseRequestDeviceAntenna[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_DEVICE_ANTENNA}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const createExerciseRequestDeviceAntenna = async (
  equipmentId: number,
): Promise<IResponse<IExerciseRequestDeviceAntenna>> => {
  try {
    const data = await axiosInstance.post(
      ExerciseRequestFormEndpoints.EXERCISE_REQUEST_DEVICE_ANTENNA,
      { equipmentId },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const updateExerciseRequestDeviceAntennaById = async (
  id: number,
  params: {
    antenna_structure_height: number
    device_antenna_id: number
  },
): Promise<IResponse<IExerciseRequestDeviceAntenna>> => {
  try {
    const data = await axiosInstance.put(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_DEVICE_ANTENNA}/${id}`,
      params,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const deleteExerciseRequestDeviceAntennaById = async (
  id: number,
): Promise<IResponse<null>> => {
  try {
    const data = await axiosInstance.delete(
      `${ExerciseRequestFormEndpoints.EXERCISE_REQUEST_DEVICE_ANTENNA}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getALlDeviceAntennas = async (
  id: number,
): Promise<IResponse<IDeviceAntenna[]>> => {
  try {
    const data = await axiosInstance.get(
      `${ExerciseRequestFormEndpoints.GET_DEVICE_ANTENNAS}/${id}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
