import { ChangeEvent, FC } from 'react'
import { TablePagination } from '@mui/material'
import { IPagination } from '../../../types/playbook.type'

import styles from './styles.module.scss'

interface IProps {
  pagination: IPagination
  isLightMode: boolean
  onPageChange: (page: number) => void
  onRowsPerPageChange: (rows: number) => void
}

const Pagination: FC<IProps> = ({
  pagination,
  isLightMode,
  onPageChange,
  onRowsPerPageChange,
}) => {
  const handleChangePage = (_: any, newPage: number) => {
    onPageChange(newPage)
  }

  const handleChangeRowsPerPage = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    onRowsPerPageChange(parseInt(event.target.value, 10))
  }

  return (
    <TablePagination
      className={styles.pagination}
      component="td"
      sx={{
        backgroundColor: isLightMode ? 'white' : '#13222e',
        color: isLightMode ? 'black' : 'white',
        alignItems: 'center',
      }}
      labelRowsPerPage=""
      count={pagination.count}
      page={pagination.skip}
      onPageChange={handleChangePage}
      rowsPerPage={pagination.take}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  )
}

export default Pagination
