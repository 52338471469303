import 'leaflet/dist/leaflet.css'
import * as L from 'leaflet'
import { useState, useContext } from 'react'
import { useEmitters } from '../../contexts/emitters.context'
import MapEntity from './map-entity'
import SideBar from './sidebar'
import styles from './styles.module.scss'
import { MapContainer, TileLayer, GeoJSON } from 'react-leaflet'
import { GeoJsonObject } from 'geojson'
import MapControl from './MapControl'
import { AppConfigContext } from '../../contexts/app-config.context'

delete (L.Icon.Default.prototype as any)._getIconUrl
;(L.Icon.Default.prototype as any)._getIconUrl = function (
  name: string,
): string {
  return require(
    'leaflet/dist/images/' +
      (name == 'icon' ? 'marker-icon' : 'marker-shadow') +
      '.png',
  )
}

const Map: React.FC = () => {
  const [uploadedKmls, setUploadedKmls] = useState<GeoJsonObject[]>([])
  const [isPopupHovered, setIsPopupHovered] = useState(false)

  const {
    data,
    areUploadsVisible, // this refers to uploaded KMLs, not frequency assignments.
  } = useEmitters()
  const { isLightMode } = useContext(AppConfigContext)

  /*
   * The 0-based position in `data` of what is currently selected.
   * This refers to the *pin*, not one of the frequency assignments at the pin.
   */
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null)

  return (
    <div
      className={styles.mapWrapper}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <SideBar
        onKmlUpload={(dataSource) => {
          setUploadedKmls((prev) => [...prev, dataSource])
        }}
      />
      <MapContainer
        id="map"
        // Turn off interaction with the map when using the popup
        dragging={!isPopupHovered}
        scrollWheelZoom={!isPopupHovered}
        doubleClickZoom={!isPopupHovered}
        className={styles.leafletContainer}
        attributionControl={false}
        center={[38.365525, -95.931632]}
        zoom={4}
      >
        <MapControl isPopupHovered={isPopupHovered} />
        <TileLayer url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png" />
        {/* We draw uploads first so they have a lower z-index than markers: */}
        {areUploadsVisible &&
          uploadedKmls.map((data, index) => (
            <GeoJSON key={index} data={data} />
          ))}
        {data.map((points, index) => {
          const point = points[0]

          if (!point) return null

          return (
            <MapEntity
              isPopupHovered={isPopupHovered}
              setIsPopupHovered={setIsPopupHovered}
              key={point.exercise_request_frequency_id}
              points={points}
              isFixed={point.station_class_type === 'Fixed'}
              groupIndex={index}
              isSelected={index == selectedIndex}
              onSelect={(p) => {
                setSelectedIndex((_prev) => p.index)
              }}
            />
          )
        })}
      </MapContainer>
    </div>
  )
}

export default Map
