import { createContext, ReactNode, useEffect, useState } from 'react'
import { IAppConfig } from '../types/app-config.type'

export const AppConfigContext = createContext<IAppConfig>({
  isLoading: false,
  isLightMode: false,
  setIsLoading: () => {},
  setIsLightMode: () => {},
})

export const AppConfigProvider = ({ children }: { children: ReactNode }) => {
  const [isLightMode, setIsLightMode] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const mode = window.localStorage.getItem('mode') || 'light'
    setIsLightMode(mode === 'light')
  }, [])

  return (
    <AppConfigContext.Provider
      value={{
        isLoading,
        isLightMode,
        setIsLoading,
        setIsLightMode,
      }}
    >
      {children}
    </AppConfigContext.Provider>
  )
}
