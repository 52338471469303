import { updateFrequencyRadiusCache } from '../services/spectrum-manager.service'
import {
  IExerciseRequestDeviceAntenna,
  IExerciseRequestEquipment,
  IExerciseRequestStationClass,
} from '../types/equipment.type'
import {
  IHopset,
  ISFAFContents,
  ISFAFFrequency,
} from '../types/spectrum-manager.type'
import { IExerciseRequestFrequencySingle } from '../types/exercise.type'
import { IAgencyOverride } from '../types/auth.type'

declare global {
  interface Array<T> {
    pushUnique(item: T): boolean
  }
}

// Helper function to pad numbers with leading zeros
function zeroPad(num: string | number, places: number) {
  return String(num).padStart(places, '0')
}

function formatDate(date: Date, isYearSliced?: boolean) {
  let year = date.getFullYear().toString()
  if (isYearSliced) {
    year = date.getFullYear().toString().slice(-2)
  }

  let month = (date.getMonth() + 1).toString().padStart(2, '0')
  let day = date.getDate().toString().padStart(2, '0')
  return year + month + day
}

async function update_radius_cache(
  record_id: number,
  radius_306: string,
  radius_406: string,
) {
  await updateFrequencyRadiusCache(record_id, { radius_306, radius_406 })
}

function encode_freq(
  start_ind: string,
  start_freq: number,
  is_band: boolean,
  end_ind: string,
  end_freq: number,
) {
  let out = ''
  out += start_ind
  out += start_freq
  if (end_ind != null && end_freq != null && is_band) {
    out += '-'
    if (end_ind != start_ind) {
      out += end_ind
    }
    out += end_freq
  }
  return out
}

function encode343(data: any) {
  let retval = ''
  if (data) {
    retval = data
    if (!data.includes('J/F')) {
      retval = 'J/F 12/' + zeroPad('' + data, 5)
    }
  }
  return retval
}

function encodeM015(irac: string, sps: string) {
  let retval = ''
  if (irac) {
    retval += 'IRAC ' + irac + ','
  }
  if (sps) {
    if (irac) {
      retval += 'SPS-' + sps
    } else {
      //special case for old equipment
      retval += sps
    }
  }
  if (retval.length > 0) {
    retval = 'M015,' + retval
  }
  return retval
}

function splitText(text: string, maxLength: number) {
  if (!text) {
    return []
  }

  const words = text.split(' ')
  const result: string[] = []
  let currentLine = ''

  words.forEach((word) => {
    if ((currentLine + word).length > maxLength) {
      result.push(currentLine.trim()) // Push the current line and reset
      currentLine = word + ' '
    } else {
      currentLine += word + ' '
    }
  })

  // Push any remaining text in the current line
  if (currentLine.trim().length > 0) {
    result.push(currentLine.trim())
  }

  return result
}

function formatSFAFPhoneNumber(str: string) {
  if (str) {
    return String(str).replace(/\D/g, '')
  }
  return ''
}

function encode_sfaf_location(
  latDeg: number,
  latMin: number,
  latSec: number,
  longDeg: number,
  longMin: number,
  longSec: number,
) {
  // Convert latitude degrees, minutes, and seconds into a formatted string
  let latDirection = latDeg >= 0 ? 'N' : 'S' // North or South based on the sign of the degrees
  latDeg = Math.abs(latDeg) // Take absolute value of degrees
  let formattedLat = `${zeroPad(latDeg, 2)}${zeroPad(latMin, 2)}${zeroPad(latSec, 2)}${latDirection}`

  // Convert longitude degrees, minutes, and seconds into a formatted string
  let longDirection = longDeg >= 0 ? 'E' : 'W' // East or West based on the sign of the degrees
  longDeg = Math.abs(longDeg) // Take absolute value of degrees
  let formattedLong = `${zeroPad(longDeg, 2)}${zeroPad(longMin, 2)}${zeroPad(longSec, 2)}${longDirection}`

  // Concatenate the latitude and longitude into the final SFAF location format
  return `${formattedLat}${formattedLong}`
}

function setFieldSendReceive(
  sfaf_obj: any,
  key_suffix: string,
  send_prefix: string,
  receive_prefix: string,
  value: string,
  send_receive: string,
) {
  if (send_receive == 'Send Only') {
    if (Array.isArray(sfaf_obj[send_prefix + key_suffix])) {
      sfaf_obj[send_prefix + key_suffix].pushUnique(value)
    } else {
      sfaf_obj[send_prefix + key_suffix] = value
    }
  } else if (send_receive == 'Receive Only') {
    if (Array.isArray(sfaf_obj[send_prefix + key_suffix])) {
      sfaf_obj[receive_prefix + key_suffix].pushUnique(value)
    } else {
      sfaf_obj[receive_prefix + key_suffix] = value
    }
  } else {
    if (Array.isArray(sfaf_obj[send_prefix + key_suffix])) {
      sfaf_obj[send_prefix + key_suffix].pushUnique(value)
      sfaf_obj[receive_prefix + key_suffix].pushUnique(value)
    } else {
      sfaf_obj[send_prefix + key_suffix] = value
      sfaf_obj[receive_prefix + key_suffix] = value
    }
  }
}

function debug(message: string) {
  console.log(message)
}

function encodeField711(radiusKm: number | null, flightLevelKft: number) {
  if (!radiusKm || !flightLevelKft) {
    return ''
  }
  // Convert radius from kilometers to nautical miles
  const nauticalMiles = radiusKm / 1.852

  // Round the nautical miles to the nearest whole number and format as a three-digit string
  const serviceRange = Math.round(nauticalMiles).toString().padStart(3, '0')

  // Format the flight level as a three-digit string
  const flightLevel = flightLevelKft.toString().padStart(3, '0')

  // Concatenate the service range and flight level
  return `${serviceRange}${flightLevel}`
}

export const generateSFAF = async (
  sfafType: string,
  sfafFrequencies: ISFAFFrequency[],
  sfafContents: ISFAFContents[],
  sfafExcludedFrequencies: IExerciseRequestFrequencySingle[],
  sfafEquipments: IExerciseRequestEquipment[],
  hopsets: IHopset[],
  frequency_id: number,
  sfafEquipmentStationClasses: Array<
    IExerciseRequestStationClass & {
      station: string
      '113_output': string
      power_ranges_name: string
      emission_designator_simple_type_name: string
      emission_designator_name?: string
    }
  >,
  sfafAntennas: Array<IExerciseRequestDeviceAntenna & { code: string }>,
  agencyOverride?: IAgencyOverride | null,
) => {
  const sfaf_obj_template = {
    '005': '',
    '010': 'N', //always N
    '102': '',
    '105': '',
    '110': '',
    '111': [],
    '113': [],
    '114': [],
    '115': [],
    '130': '',
    '140': '',
    '141': '',
    '144': '',
    '200': '',
    '201': '',
    '202': '',
    '204': '',
    '205': '',
    '206': '',
    '207': '',
    '208': '',
    '209': '',
    '300': '',
    '301': '',
    '303': '',
    '306': '',
    '340': [],
    '341': [],
    '343': [],
    //  "345": "",
    //  "346": "",
    //  "347": "",
    //  "348": "",
    //  "349": "",
    '354': [],
    //"355": "", //Remove
    //"356": [], //Remove
    '357': [],
    '358': [],
    '359': [],
    // "360": [],//Remove
    // "361": [],//Remove
    '362': [],
    '363': [],
    '400': '',
    '401': '',
    '403': '',
    '440': [],
    '443': [],
    '454': [],
    // "456": [],//Remove
    '457': [],
    '458': [],
    '459': [],
    // "460": [],//Remove
    // "461": [],//Remove
    '462': [],
    '463': [],
    '500': '',
    '501': '',
    '502': '',
    // "503": "",//Remove
    '511': '',
    '512': '',
    '520': '',
    '701': '',
    '702': '',
    '711': '',
    '716': '',
    // "801": "",//Remove
    '803': '',
    // "804": "", //Remove
    '910': '',
  }

  Array.prototype.pushUnique = function (item) {
    if (this.indexOf(item) == -1) {
      this.push(item)
      return true
    }
    return false
  }

  //////////////////////// Get SFAF Data //////////////////////////

  function get_sfaf_data(sfaf_obj: any, i: number) {
    debug(
      `start:i:${i},location:${sfafFrequencies[i].exercise_request_location_transmitter_location},frequencyID:${sfafFrequencies[i].exercise_request_assigned_frequency_id}`,
    )
    //find sfaf content index
    let query_get_sfaf_content_i = 0
    for (let content_i = 0; content_i < sfafContents.length; content_i++) {
      if (
        sfafContents[content_i]?.id ==
        sfafFrequencies[i].exercise_request_frequency_exercise_request_id
      ) {
        query_get_sfaf_content_i = content_i
        break
      }
    }
    const date = new Date()
    const year = date.getFullYear()
    const yearSuffix = year.toString().slice(-2)

    //005
    sfaf_obj['005'] = sfafContents[query_get_sfaf_content_i].classification_name
    sfaf_obj['102'] =
      sfafFrequencies[i].id_override ??
      (agencyOverride?.id_prefix || 'ARMY') +
        yearSuffix +
        zeroPad(sfafFrequencies[i].exercise_request_assigned_frequency_id, 4)
    sfaf_obj['110'] = encode_freq(
      sfafFrequencies[i].exercise_request_frequency_single_start_indicator,
      sfafFrequencies[i].exercise_request_frequency_single_start_freq,
      true,
      sfafFrequencies[i].exercise_request_frequency_single_end_indicator,
      sfafFrequencies[i].exercise_request_frequency_single_end_freq,
    )
    if (sfafFrequencies[i].exercise_request_assigned_frequency_value) {
      sfaf_obj['110'] =
        sfafFrequencies[i].exercise_request_assigned_frequency_value
    }
    sfaf_obj['105'] =
      sfafFrequencies[i]
        .exercise_request_assigned_frequency_list_serial_number ?? ''

    for (let exi = 0; exi < sfafExcludedFrequencies.length; exi++) {
      if (
        sfafExcludedFrequencies[exi].exercise_request_frequency_id ==
        sfafFrequencies[i].exercise_request_frequency_id
      ) {
        sfaf_obj['111'].pushUnique(
          encode_freq(
            sfafExcludedFrequencies[exi].start_indicator,
            sfafExcludedFrequencies[exi].start_freq,
            sfafExcludedFrequencies[exi].is_band,
            sfafExcludedFrequencies[exi].end_indicator,
            sfafExcludedFrequencies[exi].end_freq,
          ),
        )
      }
    }

    //Send location
    sfaf_obj['300'] = sfafFrequencies[i].state_region_type_code //State/Region
    sfaf_obj['301'] =
      sfafFrequencies[i].exercise_request_location_transmitter_location //City/Installation/Location
    sfaf_obj['303'] = encode_sfaf_location(
      sfafFrequencies[i].exercise_request_location_latitude_deg,
      sfafFrequencies[i].exercise_request_location_latitude_min,
      sfafFrequencies[i].exercise_request_location_latitude_sec,
      sfafFrequencies[i].exercise_request_location_longitude_deg,
      sfafFrequencies[i].exercise_request_location_longitude_min,
      sfafFrequencies[i].exercise_request_location_longitude_sec,
    ) //LatitudeLongitude SFAF OUTPUT

    //Receive location
    if (sfafFrequencies[i].is_different_receive_location) {
      sfaf_obj['400'] = sfafFrequencies[i].receive_state_region_type_code //State/Region
      sfaf_obj['401'] =
        sfafFrequencies[
          i
        ].receive_exercise_request_location_transmitter_location //City/Installation/Location
      sfaf_obj['403'] = encode_sfaf_location(
        sfafFrequencies[i].receive_exercise_request_location_latitude_deg,
        sfafFrequencies[i].receive_exercise_request_location_latitude_min,
        sfafFrequencies[i].receive_exercise_request_location_latitude_sec,
        sfafFrequencies[i].receive_exercise_request_location_longitude_deg,
        sfafFrequencies[i].receive_exercise_request_location_longitude_min,
        sfafFrequencies[i].receive_exercise_request_location_longitude_sec,
      ) //LatitudeLongitude SFAF OUTPUT
    } else {
      sfaf_obj['400'] = sfafFrequencies[i].state_region_type_code //State/Region
      sfaf_obj['401'] =
        sfafFrequencies[i].exercise_request_location_transmitter_location //City/Installation/Location
      sfaf_obj['403'] = encode_sfaf_location(
        sfafFrequencies[i].exercise_request_location_latitude_deg,
        sfafFrequencies[i].exercise_request_location_latitude_min,
        sfafFrequencies[i].exercise_request_location_latitude_sec,
        sfafFrequencies[i].exercise_request_location_longitude_deg,
        sfafFrequencies[i].exercise_request_location_longitude_min,
        sfafFrequencies[i].exercise_request_location_longitude_sec,
      ) //LatitudeLongitude SFAF OUTPUT
    }

    //equipment
    sfaf_obj['113'] = []
    sfaf_obj['114'] = []
    sfaf_obj['115'] = []

    let send_antenna_radius: null | number = null
    let receive_antenna_radius: null | number = null
    let antenna_radius_406: null | number = null
    let antenna_radius_306: null | string = null
    let sender_fixed = true
    let receiver_fixed = true

    let flightLevel = 0

    sfaf_obj['340'] = []
    sfaf_obj['343'] = []
    sfaf_obj['440'] = []
    sfaf_obj['443'] = []
    sfaf_obj['501'] = []
    const value = sfafFrequencies[i]?.location_afc_coord_note
    if (value != null) {
      sfaf_obj['501']?.pushUnique(value)
    } // Notes Free-Text Comments

    for (let ei = 0; ei < sfafEquipments.length; ei++) {
      let elevation_antenna_base: string = ''
      let antenna_feed_point_height = ''

      if (
        sfafEquipments[ei].exercise_request_frequency_id ==
        sfafFrequencies[i].exercise_request_frequency_id
      ) {
        const encodedValueM015 = encodeM015(
          sfafEquipments[ei]?.f_501_irac,
          sfafEquipments[ei]?.f_501_sps,
        )
        if (sfafEquipments[ei].send_receive == 'Send Only') {
          sfaf_obj['340'].pushUnique(sfafEquipments[ei].equipment_name)
          sfaf_obj['343'].pushUnique(encode343(sfafEquipments[ei].f_343))

          if (encodedValueM015 != null) {
            sfaf_obj['501']?.pushUnique(encodedValueM015)
          }
        } else if (sfafEquipments[ei].send_receive == 'Receive Only') {
          sfaf_obj['440'].pushUnique(sfafEquipments[ei].equipment_name)
          sfaf_obj['443'].pushUnique(encode343(sfafEquipments[ei].f_343))
        } else {
          //send and receive
          sfaf_obj['340'].pushUnique(sfafEquipments[ei].equipment_name)
          sfaf_obj['343'].pushUnique(encode343(sfafEquipments[ei].f_343))
          sfaf_obj['440'].pushUnique(sfafEquipments[ei].equipment_name)
          sfaf_obj['443'].pushUnique(encode343(sfafEquipments[ei].f_343))
          if (encodedValueM015 != null) {
            sfaf_obj['501']?.pushUnique(encodedValueM015)
          }
        }

        //station class
        for (let sc = 0; sc < sfafEquipmentStationClasses.length; sc++) {
          if (
            sfafEquipments[ei]?.id ==
            sfafEquipmentStationClasses[sc].exercise_request_device_id
          ) {
            debug(
              `using equipment:${sfafEquipments[ei]?.id},station class:${sfafEquipmentStationClasses[sc].exercise_request_device_id}`,
            )
            sfaf_obj['113'].push(sfafEquipmentStationClasses[sc]['113_output'])
            if (sfafFrequencies[i].f_114) {
              sfaf_obj['114'].push(sfafFrequencies[i].f_114)
            } else if (
              sfafEquipmentStationClasses[sc].emission_designator_name
            ) {
              sfaf_obj['114'].push(
                sfafEquipmentStationClasses[sc].emission_designator_name,
              )
            } else if (
              sfafEquipmentStationClasses[sc]
                .emission_designator_simple_type_name
            ) {
              sfaf_obj['114'].push(
                '**' +
                  sfafEquipmentStationClasses[sc]
                    .emission_designator_simple_type_name +
                  '**',
              )
            } else {
              sfaf_obj['114'].push('')
            }

            sfaf_obj['115'].pushUnique(
              sfafEquipmentStationClasses[sc].power_ranges_name +
                '' +
                sfafEquipmentStationClasses[sc].power,
            )
            //update Radius of used
            if (
              sfafEquipmentStationClasses[sc].station == 'Aeronautical' ||
              sfafEquipmentStationClasses[sc].station == 'Maritime' ||
              sfafEquipmentStationClasses[sc].station == 'Mobile' ||
              sfafEquipmentStationClasses[sc].station == 'Experimental'
            ) {
              //override radius in send / receive
              if (sfafEquipments[ei].send_receive == 'Send Only') {
                send_antenna_radius = Math.max(
                  send_antenna_radius || 0,
                  sfafEquipmentStationClasses[sc].antenna_radius,
                )
              } else if (sfafEquipments[ei].send_receive == 'Receive Only') {
                receive_antenna_radius = Math.max(
                  receive_antenna_radius || 0,
                  sfafEquipmentStationClasses[sc].antenna_radius,
                )
              } else {
                send_antenna_radius = Math.max(
                  send_antenna_radius || 0,
                  sfafEquipmentStationClasses[sc].antenna_radius,
                )
                receive_antenna_radius = Math.max(
                  receive_antenna_radius || 0,
                  sfafEquipmentStationClasses[sc].antenna_radius,
                )
              }
            }

            if (sfafEquipmentStationClasses[sc].station == 'Aeronautical') {
              flightLevel = Math.max(
                flightLevel,
                sfafEquipmentStationClasses[sc].flight_level,
              )
            }
            if (sfafEquipmentStationClasses[sc].station == 'Fixed') {
              //elevation_antenna_base = sfafEquipmentStationClasses[sc].antenna_elevation
              elevation_antenna_base = `${sfafFrequencies[i].exercise_request_location_elevation}`

              antenna_feed_point_height = `${sfafEquipmentStationClasses[sc].antenna_feed_point_height}`

              //  If the Location has a 306, and the user says the Distant end is the same location (thus the same radius) but then says the Station Configuration is fixed: 406. [number]
              if (
                sfafFrequencies[i].exercise_request_location_radius != 0 &&
                !sfafFrequencies[i].is_different_receive_location &&
                sfafEquipments[ei].send_receive == 'Send Only'
              ) {
                //If equipment is marked as a sender with a station class of fixed, radius will default to 406.
                antenna_radius_406 =
                  sfafFrequencies[i].exercise_request_location_radius
              } else if (sfafEquipments[ei].send_receive == 'Receive Only') {
                //If equipment is marked as a receiver with a station class of fixed, radius will default to 306 with a suffix of T.
                antenna_radius_306 =
                  sfafFrequencies[i].exercise_request_location_radius + 'T'
              } else {
                //send and receive
                //If equipment is marked as a sender with a station class of fixed AND equipment is marked as a receiver with has a station class of fixed, neither 306 or 406 will be applied.
              }
            } else {
              //  If the Location has a 306, and the user says the Distant end is the same location (thus the same radius) but then says the Station Configuration is fixed: 406. [number]
              if (sfafEquipments[ei].send_receive == 'Send Only') {
                sender_fixed = false
              } else if (sfafEquipments[ei].send_receive == 'Receive Only') {
                receiver_fixed = false
              } else {
                //send and receive
                sender_fixed = false
                receiver_fixed = false
              }
            }
          }
        }

        setFieldSendReceive(
          sfaf_obj,
          '40',
          '3',
          '4',
          sfafEquipments[ei].equipment_name,
          sfafEquipments[ei].send_receive,
        )
        //Note these two are both "3" because "441" should not be in the SFAF according to the client
        setFieldSendReceive(
          sfaf_obj,
          '41',
          '3',
          '3',
          sfafEquipments[ei].number_of_stations_341,
          sfafEquipments[ei].send_receive,
        )
        //setFieldSendReceive(sfaf_obj, "43", "3", "4", sfafEquipments[ei].equipment_certification_number, sfafEquipments[ei].send_receive) //this is done above

        //antenna
        for (let ea = 0; ea < sfafAntennas.length; ea++) {
          if (
            sfafEquipments[i]?.id == sfafAntennas[ea].exercise_request_device_id
          ) {
            setFieldSendReceive(
              sfaf_obj,
              '54',
              '3',
              '4',
              sfafAntennas[ea].code,
              sfafEquipments[ei].send_receive,
            )
            //setFieldSendReceive(sfaf_obj, "55", "3", "4", "", sfafEquipments[ei].send_receive) //Remove, we don’t have enough data from certification documents to populate this
            //setFieldSendReceive(sfaf_obj, "56", "3", "4", "", sfafEquipments[ei].send_receive) //Remove, this is a CENTCOM or EUCOM only field, not necessary until much later versions
            setFieldSendReceive(
              sfaf_obj,
              '57',
              '3',
              '4',
              '0',
              sfafEquipments[ei].send_receive,
            )
            setFieldSendReceive(
              sfaf_obj,
              '58',
              '3',
              '4',
              elevation_antenna_base,
              sfafEquipments[ei].send_receive,
            ) // [Elevation at Antenna Base (meters)] .. only available if station class is Fixed
            setFieldSendReceive(
              sfaf_obj,
              '59',
              '3',
              '4',
              antenna_feed_point_height,
              sfafEquipments[ei].send_receive,
            ) // [Antenna Feed Point Height (meters)] .. only available if station class is Fixed
            setFieldSendReceive(
              sfaf_obj,
              '60',
              '3',
              '4',
              '',
              sfafEquipments[ei].send_receive,
            ) //Leave blank/remove
            setFieldSendReceive(
              sfaf_obj,
              '61',
              '3',
              '4',
              '',
              sfafEquipments[ei].send_receive,
            ) //Leave blank/remove
            setFieldSendReceive(
              sfaf_obj,
              '62',
              '3',
              '4',
              'ND',
              sfafEquipments[ei].send_receive,
            ) //Static data: ND
            setFieldSendReceive(
              sfaf_obj,
              '63',
              '3',
              '4',
              'V',
              sfafEquipments[ei].send_receive,
            ) //Static data: V
          }
        }
      }
    }

    send_antenna_radius =
      send_antenna_radius ?? sfafFrequencies[i].exercise_request_location_radius
    receive_antenna_radius =
      receive_antenna_radius ??
      sfafFrequencies[i].receive_exercise_request_location_radius

    debug(
      `sender_fixed:${sender_fixed},receiver_fixed:${receiver_fixed},send_antenna_radius:${send_antenna_radius},receive_antenna_radius:${receive_antenna_radius}`,
    )

    //306, 406 fixed overrides
    //    A SFAF can either contain: a 306 field, a 406 field, or neither field.  Never both.
    //  Use of a 306 with a suffix of B means both the sender and receiver are moving within the radius.
    //  Use of a 306 with a suffix of T means only the sender is moving within the radius, i.e. the receiver is fixed in place.
    //  Use of a 406 means only the receiver is moving within the radius, i.e. the sender is fixed in place.
    if (sender_fixed && !receiver_fixed) {
      sfaf_obj['406'] = antenna_radius_406
    } else if (receiver_fixed && !sender_fixed) {
      sfaf_obj['306'] = antenna_radius_306
    } else if (receiver_fixed && sender_fixed) {
      ////If equipment is marked as a sender with a station class of fixed AND equipment is marked as a receiver with has a station class of fixed, neither 306 or 406 will be applied.
      sfaf_obj['306'] = ''
    } else {
      //    306-406 Calculation:
      //  If the Location has a 306, and the user says the Distant end is the same location (thus the same radius): 306. [number]B
      //  If the Location has a 306, and the user says the Distant end is the different location and different radius: 306. [bigger number]B
      //  If the Location has a 306, and the user says the Distant end is the same location (thus the same radius) but then says the Station Configuration is fixed: 406. [number] (this is implemented in the antenna part)
      //  If the Location has a 306, and the user says the Distant end is the different location but the Distant End doesn’t have a radius: 306. [number]T
      if (
        send_antenna_radius != 0 &&
        !sfafFrequencies[i].is_different_receive_location
      ) {
        sfaf_obj['306'] = send_antenna_radius + 'B'
      } else if (
        send_antenna_radius != 0 &&
        sfafFrequencies[i].is_different_receive_location &&
        `${receive_antenna_radius}`[i] != '0'
      ) {
        send_antenna_radius = Math.max(
          send_antenna_radius || 0,
          receive_antenna_radius || 0,
        )
        sfaf_obj['306'] = send_antenna_radius + 'B'
      } else if (
        send_antenna_radius != 0 &&
        sfafFrequencies[i].is_different_receive_location &&
        receive_antenna_radius == 0
      ) {
        sfaf_obj['306'] = send_antenna_radius + 'T'
      }
    }

    //*************This overrides antenna values for Emerald Warror. In a later stage this should be taken from the antenna table.
    //When you change this, change the editing interface as well (bind to the Antenna table) and remove these fields from the frequency table
    sfaf_obj['354'] = []
    sfaf_obj['454'] = []
    sfaf_obj['357'] = []
    sfaf_obj['457'] = []
    sfaf_obj['362'] = []
    sfaf_obj['462'] = []
    sfaf_obj['363'] = []
    sfaf_obj['463'] = []

    //This overrides antenna values.
    sfaf_obj['354'] = sfafFrequencies[i].f_354 ?? ''
    sfaf_obj['454'] = sfafFrequencies[i].f_454 ?? ''
    sfaf_obj['357'] = sfafFrequencies[i].f_357 ?? ''
    sfaf_obj['457'] = sfafFrequencies[i].f_457 ?? ''
    sfaf_obj['362'] = sfafFrequencies[i].f_362 ?? ''
    sfaf_obj['462'] = sfafFrequencies[i].f_462 ?? ''
    sfaf_obj['363'] = sfafFrequencies[i].f_363 ?? ''
    sfaf_obj['463'] = sfafFrequencies[i].f_463 ?? ''
    //***********END of overrides

    //TBD: After Emerald Wariod these should be changed to input fields
    sfaf_obj['130'] = agencyOverride?.f_130 ?? '3'
    sfaf_obj['140'] =
      sfafFrequencies[i]?.start_date?.format('YYYYMMDD') ?? '20240224'
    sfaf_obj['141'] =
      sfafFrequencies[i]?.end_date?.format('YYYYMMDD') ?? '20240217'
    sfaf_obj['144'] = agencyOverride?.f_144 ?? 'U'

    sfaf_obj['200'] = agencyOverride?.f_200 ?? 'USAF' //Agency
    sfaf_obj['201'] = agencyOverride?.f_201 ?? 'SOCOM' //Unified Command
    sfaf_obj['202'] = agencyOverride?.f_202 ?? 'AFSOC' //Unified Command Service
    sfaf_obj['204'] = agencyOverride?.f_204 ?? 'AFSOC' //Command
    sfaf_obj['205'] = agencyOverride?.f_205 ?? 'AFSOC' //Subcommand
    sfaf_obj['206'] = agencyOverride?.f_206 ?? 'AFSOCSM' //Installation Frequency Manager
    sfaf_obj['207'] =
      sfafContents[query_get_sfaf_content_i].operating_unit_name ?? '' //Operating Unit
    sfaf_obj['208'] =
      sfafContents[query_get_sfaf_content_i].operating_sub_unit_name ?? '' //User Net/Code
    sfaf_obj['209'] = sfafFrequencies[i].location_afc ?? '' //Area AFC/DoD AFC/Other Organizations

    sfaf_obj['500'] = sfafFrequencies[i].location_afc_code ?? '' //   IRAC Notes

    sfaf_obj['511'] = sfafFrequencies[i].major_function_511_name ?? ''
    sfaf_obj['512'] = sfafFrequencies[i].intermediate_function_512_name ?? ''
    sfaf_obj['513'] = sfafFrequencies[i].detailed_function_513_name ?? ''
    sfaf_obj['520'] = splitText(
      sfafFrequencies[i].exercise_request_frequency_supplementary_details ?? '',
      72,
    ) //Can this box limit each line to a maximum of 72 characters, maximum of 15 lines? This will ensure SXXI can accept it appropriately.

    sfaf_obj['701'] = 'T01' //    Frequency Action Officer
    sfaf_obj['702'] = sfafFrequencies[i].f_702 ?? 'AFSOC 2024-001' // Control/Request Number
    sfaf_obj['711'] = 'TBD' //    Aeronautical Service Range and Height
    sfaf_obj['711'] = encodeField711(send_antenna_radius, flightLevel)
    sfaf_obj['716'] = '4' //  Usage Code - for now always 4

    //  sfaf_obj["801"] = "TBD"; //   Coordination Data/Remarks //Drop this one, not needed for the Pilot
    sfaf_obj['803'] =
      sfafContents[query_get_sfaf_content_i].username.slice(0, 42) +
      ',' +
      formatSFAFPhoneNumber(sfafContents[query_get_sfaf_content_i].user_phone) +
      ',' +
      formatDate(
        new Date(sfafContents[query_get_sfaf_content_i].created_at),
        true,
      ) //    Point of Contact
    // sfaf_obj["804"] = sfaf_obj["110"] + ",XX," + sfafFrequencies[i].number_of_frequencies[i]; //    Tuning Range/Tuning Increments/Number of Frequencies //Remove
    sfaf_obj['910'] = sfafFrequencies[i].f_910 ?? 'Emerald Warrior 24' //    Exercise Project  //All Pilot SFAFs will have 910. Emerald Warrior 24

    //  sfaf_obj["AAA"] = "TBD"; //   Quantity of Frequencies Requested // not in SFAF
    //  sfaf_obj["BBB"] = "TBD"; //   Type of Request // not in SFAF
    //  sfaf_obj["CCC"] = "TBD"; //   Receiver Data Identical to Transmitter Data? // not in SFAF
    if (sfafType != 'all') {
      update_radius_cache(
        sfafFrequencies[i].exercise_request_frequency_id,
        sfaf_obj['306'],
        sfaf_obj['406'],
      )
    }
  }

  function get_sfaf_hopset_data(sfaf_obj: any, i: number, j: number) {
    if (sfafFrequencies[i].saturn) {
      sfaf_obj['105'] = hopsets[j].f_105
      sfaf_obj['110'] = hopsets[j].f_110
      sfaf_obj['113'] = hopsets[j].f_113
      sfaf_obj['114'] = hopsets[j].f_114
      sfaf_obj['115'] = hopsets[j].f_115
      sfaf_obj['113/02'] = hopsets[j].f_113_02
      sfaf_obj['114/02'] = hopsets[j].f_114_02
      sfaf_obj['115/02'] = hopsets[j].f_115_02

      sfaf_obj['503'] = sfafFrequencies[i].hopset
      sfaf_obj['500'] = 'S189'
      sfaf_obj['500/02'] = 'S362'
      sfaf_obj['500/03'] = sfafFrequencies[i].location_afc_code
      sfaf_obj['501'] = 'M015'
      sfaf_obj['501/02'] = 'M018'
      sfaf_obj['502'] =
        'FREQS CAN ONLY BE USED IN HOPPING MODE AND CANNOT BE USED FOR SINGLE CHANNEL'
      sfaf_obj['502/02'] = 'COMM. HOPSETS CANNOT BE MIXED.'
      sfaf_obj['503/02'] = 'FLL=000'
    }
  }

  //////////////////////// Encode SFAF ////////////////////////////

  function encode_field_005(value: string) {
    return 'U' + value
  }

  function encode_sfaf(sfaf_obj: any) {
    sfaf_obj['005'] = encode_field_005(sfaf_obj['005'])
  }

  //////////////////////// SFAF toString //////////////////////////

  function get_sfaf_string(sfaf_obj: any) {
    let sfaf = ''
    for (const [key, value] of Object.entries(sfaf_obj).sort()) {
      if (key == '113' || key == '114' || key == '115') {
        if (key == '113') {
          if (Array.isArray(value) && value.length > 0) {
            //print the first
            sfaf += '113. ' + sfaf_obj['113'][0] + '\n'
            sfaf += '114. ' + sfaf_obj['114'][0] + '\n'
            sfaf += '115. ' + sfaf_obj['115'][0] + '\n'
            for (let i = 1; i < value.length; i++) {
              sfaf +=
                '113' +
                '/' +
                zeroPad(i + 1, 2) +
                '. ' +
                sfaf_obj['113'][i] +
                '\n'
              sfaf +=
                '114' +
                '/' +
                zeroPad(i + 1, 2) +
                '. ' +
                sfaf_obj['114'][i] +
                '\n'
              sfaf +=
                '115' +
                '/' +
                zeroPad(i + 1, 2) +
                '. ' +
                sfaf_obj['115'][i] +
                '\n'
            }
          } else {
            sfaf += '113. ' + sfaf_obj['113'] + '\n'
            sfaf += '114. ' + sfaf_obj['114'] + '\n'
            sfaf += '115. ' + sfaf_obj['115'] + '\n'
          }
        }
      } else if (Array.isArray(value) && value.length > 0) {
        //print the first
        sfaf += key + '. ' + value[0] + '\n'

        for (let i = 1; i < value.length; i++) {
          if (key == '520') {
            if (i < 15) {
              //Can this box limit each line to a maximum of 72 characters, maximum of 15 lines? This will ensure SXXI can accept it appropriately.
              sfaf += key + '. ' + value[i] + '\n'
            }
          } else {
            sfaf += key + '/' + zeroPad(i + 1, 2) + '. ' + value[i] + '\n'
          }
        }
      } else {
        sfaf += key + '. ' + value + '\n'
      }
    }
    return sfaf.toUpperCase()
  }

  ///////////////////// main ////////////////////////////////////////

  let all_sfafs = ''

  try {
    for (let i = 0; i < sfafFrequencies.length; i++) {
      if (
        sfafType == 'single' &&
        sfafFrequencies[i].exercise_request_frequency_id != frequency_id
      ) {
        continue
      }
      if (sfafFrequencies[i].saturn) {
        // query_get_hopset.trigger({
        //   additionalScope: {
        //     hopset_group: sfafFrequencies[i].hopset,
        //   },
        // })
        for (let j = 0; j < 40; j++) {
          let sfaf_object = JSON.parse(JSON.stringify(sfaf_obj_template))
          get_sfaf_data(sfaf_object, i)
          get_sfaf_hopset_data(sfaf_object, i, j)
          encode_sfaf(sfaf_object)

          let sfaf_string = get_sfaf_string(sfaf_object)
          all_sfafs += sfaf_string
        }
      } else {
        let sfaf_object = JSON.parse(JSON.stringify(sfaf_obj_template))
        get_sfaf_data(sfaf_object, i)
        encode_sfaf(sfaf_object)
        let sfaf_string = get_sfaf_string(sfaf_object)
        all_sfafs += sfaf_string
      }
    }
    if (sfafFrequencies.length == 0) {
      all_sfafs =
        'Insufficient data. You need at least one location and one frequency, connected.'
    }
  } catch (error) {
    all_sfafs = 'Error building SFAF' + error
    console.log(error)
  } finally {
    return all_sfafs
  }
}
