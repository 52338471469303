import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  Box,
  List,
  ListItem,
  Collapse,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  IconButton,
  CssBaseline,
} from '@mui/material'
import { styled, Theme } from '@mui/material/styles'
import MuiDrawer from '@mui/material/Drawer'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { ManagerDashboardTabs } from '../../../../utils/constants'

import styles from './styles.module.scss'

const drawerWidth = 260
const rootPathname = '/spectrum-manager-dashboard'

const openedMixin = (theme: Theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
})

const closedMixin = (theme: Theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(5)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(10)} + 1px)`,
  },
})

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  borderRight: '1px solid white',
  variants: [
    {
      props: ({ open }) => open,
      style: {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
      },
    },
    {
      props: ({ open }) => !open,
      style: {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
      },
    },
  ],
}))

const Sidebar = () => {
  const [open, setOpen] = useState(true)
  const [collapsedTabs, setCollapsedTabs] = useState<Record<number, boolean>>(
    {},
  )
  const navigate = useNavigate()
  const location = useLocation()

  const handleDrawerClose = () => {
    setOpen(!open)
  }

  const handleTabSelect = (
    link: string,
    hasSubItems: boolean,
    index: number,
  ) => {
    if (hasSubItems) {
      setCollapsedTabs((prev) => ({
        ...prev,
        [index]: !prev[index],
      }))
    } else {
      navigate(link)
    }
  }
  const isActive = (link: string) =>
    location.pathname === `${rootPathname}/${link}`

  return (
    <Box className={styles.sidebar}>
      <CssBaseline />
      <Drawer
        sx={{
          '& .MuiDrawer-root': {
            position: 'absolute',
          },
          '& .MuiPaper-root': {
            position: 'absolute',
            overflow: 'visible',
          },
        }}
        variant="permanent"
        open={open}
      >
        <Box className={`${styles.closeIcon} drawerIcon`}>
          <IconButton onClick={handleDrawerClose}>
            {!open ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </Box>
        <List>
          {ManagerDashboardTabs.map((tab, index) => (
            <div key={tab.label}>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  onClick={() =>
                    handleTabSelect(
                      tab.link || '',
                      Boolean(tab.subItems),
                      index,
                    )
                  }
                  className={`${styles.listItemBtn} ${
                    isActive(tab.link || '') ? styles.active : ''
                  }`}
                  sx={
                    open
                      ? { justifyContent: 'initial' }
                      : { justifyContent: 'center' }
                  }
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                      mr: open ? 3 : 'auto',
                    }}
                  >
                    <tab.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={tab.label}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {tab.subItems && (
                    <>
                      {collapsedTabs[index] ? <ExpandLess /> : <ExpandMore />}
                    </>
                  )}
                </ListItemButton>
              </ListItem>
              {tab.subItems && (
                <Collapse
                  in={collapsedTabs[index]}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    {tab.subItems.map((subItem) => (
                      <ListItemButton
                        key={subItem.label}
                        sx={{ pl: 4 }}
                        onClick={() =>
                          navigate(`${rootPathname}/${subItem.link}`)
                        }
                        className={isActive(subItem.link) ? styles.active : ''}
                      >
                        <ListItemIcon>
                          <subItem.icon />
                        </ListItemIcon>
                        <ListItemText
                          primary={subItem.label}
                          sx={{ opacity: open ? 1 : 0 }}
                        />
                      </ListItemButton>
                    ))}
                  </List>
                </Collapse>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  )
}

export default Sidebar
