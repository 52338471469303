import {
  memo,
  useMemo,
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react'
import { toast } from 'react-toastify'
import { LatLngTuple } from 'leaflet'
import { SelectChangeEvent } from '@mui/material'
import { Marker, Tooltip, Circle, useMapEvent } from 'react-leaflet'
import UpdateModal from '../map-entity/UpdateModal'
import CustomPoint from '../CustomPoint'
import PointDetails from '../PointDetails'
import EmitterDrawer from '../EmitterDrawer'
import DraggableMarker from '../DraggingMarker'
import HeatmapComponent from '../Heatmap'
import { useEmitters } from '../../../contexts/emitters.context'
import { createHeatmapSimulation } from '../../../services/cloudRF.service'
import { IHeatmapData, IHeatmapParams } from '../../../types/cloudRF.type'
import { ICustomPoint } from '../../../types/emitter.type'
import { ISelectedPoint } from '../types'
import { IFrequencyAssignmentWithCoordinates } from '../../../types/exercise.type'
import {
  calculateDistance,
  calculateDestination,
} from '../../../utils/functions'
import HeatmapSettingsModal from './HeatmapSettingsModal'
import prepareHeatmapParams from '../../../utils/prepareAreaRequestData'

interface IProps {
  isFixed: boolean
  points: IFrequencyAssignmentWithCoordinates[]
  groupIndex: number
  isPopupHovered: boolean
  setIsPopupHovered: Dispatch<SetStateAction<boolean>>
  isSelected: boolean
  onSelect: (data: ISelectedPoint) => void
}

export enum ActionTypes {
  ADD = 'Add',
  NONE = 'None',
  MOVE = 'Move',
  POINTS = 'Points',
  ANALYZE = 'Analyze',
}

export interface IHeatmapLoadingStatus {
  [id: string]: {
    heatmap: number
    simulation: number
  }
}

const MapEntity: React.FC<IProps> = ({
  isFixed,
  points,
  isPopupHovered,
  setIsPopupHovered,
  groupIndex,
  isSelected,
  onSelect,
}) => {
  const [isMoving, setIsMoving] = useState(false)
  const [isAdding, setIsAdding] = useState(false)
  const [showRadius, setShowRadius] = useState(false)
  const [isDragging, setIsDragging] = useState(false)
  const [actionType, setActionType] = useState<ActionTypes>(ActionTypes.NONE)
  const [showHeatmap, setShowHeatmap] = useState(false)
  const [customPoints, setCustomPoints] = useState<ICustomPoint[]>([])
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [lastSelectedPoint, setLastSelectedPoint] = useState('')
  const [simulationLoading, setSimulationLoading] = useState(false)
  const [isManipulatingPoints, setIsManipulatingPoints] = useState(false)
  const [isDefaultPointsAdded, setIsDefaultPointsAdded] = useState(false)
  const [showHeatmapSimulation, setShowHeatmapSimulation] = useState(false)
  const [heatmapSimulation, setHeatmapSimulation] =
    useState<IHeatmapData | null>(null)
  const [isHeatmapParamsModalOpen, setIsHeatmapParamsModalOpen] =
    useState(false)

  const { selectEmitterData, handleChangeDataBy } = useEmitters()

  // Don't build this data structure every time we render, only once. See heatmapLoadingStatus:
  const startingHeatmapLoadingStatus = useMemo<IHeatmapLoadingStatus>(
    () =>
      points.reduce(
        (result, elem) => ({
          ...result,
          [elem.id_override]: { heatmap: 0, simulation: 0 },
        }),
        {},
      ),
    [points],
  )

  const selectedHeatmap = useMemo(() => {
    return points ? points.find((el) => el.selected)?.heatmap : null
  }, [points])

  // The specific frequency assignment selected in the dropdown,
  // since a pin can have several:
  const selectedFrequencyAssignment =
    useMemo<IFrequencyAssignmentWithCoordinates>(
      () => points.find((el) => el.selected)!,
      [points],
    )

  const [position, setPosition] = useState<LatLngTuple>([
    selectedFrequencyAssignment.latitude,
    selectedFrequencyAssignment.longitude,
  ])

  /*
   * Contains an object with one entry for each frequency assignment,
   * then each of those has one entry for each heatmap type (i.e. the main heatmap and the simulation).
   * The number is the number of failures loading the image.
   * CloudRF often gives us a result from the heatmap request
   * before they are actually ready to serve the resulting png,
   * so then we get an error when we try to draw it on the map.
   * We want to retry a few times then give up.
   */
  const [heatmapLoadingStatus, setHeatmapLoadingStatus] =
    useState<IHeatmapLoadingStatus>(startingHeatmapLoadingStatus)

  useMapEvent('click', (data) => {
    if (isAdding && data && !isPopupHovered) {
      const { lat, lng } = data.latlng
      const distance = calculateDistance(
        lat,
        lng,
        selectedFrequencyAssignment.latitude,
        selectedFrequencyAssignment.longitude,
      )

      if (
        distance <= selectedFrequencyAssignment.exercise_request_location_radius
      ) {
        handleAddCustomPoint({
          ...selectedFrequencyAssignment,
          id:
            selectedFrequencyAssignment.id_override + (customPoints.length + 1),
          radius: 10, // Default value
          heatmapParams: prepareHeatmapParams({
            lat,
            lon: lng,
            frq: selectedFrequencyAssignment.exercise_request_assigned_frequency_value,
            rad: 10,
          }),
          latitude: lat,
          showHeatmap: false,
          longitude: lng,
          showRadius: false,
          heatmap: undefined,
          selected: false,
        })
      }
    }
  })

  const handleMarkerClick = async () => {
    onSelect({
      index: groupIndex,
      points: points,
      focused: true,
    })
    setLastSelectedPoint('')
  }

  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedId = event.target.value
    selectEmitterData(selectedFrequencyAssignment!.id_override, selectedId)
  }

  // TODO: We don't actually save your changes to the backend!
  const handleUpdate = async (
    updatedPoint: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => {
    if (selectedFrequencyAssignment === null) return

    if (
      updatedPoint.exercise_request_location_radius ||
      updatedPoint.exercise_request_assigned_frequency_value
    ) {
      handleChangeDataBy(
        { id_override: selectedFrequencyAssignment.id_override },
        { ...updatedPoint, loading: true, heatmap: null },
      )

      /*
       * Replace the heatmap with a new image.
       * TODO: We call createHeatmapSimulation here, but I think it should be createHeatmap. Right?
       */
      const resp = await createHeatmapSimulation(
        selectedFrequencyAssignment.heatmapParams,
      )

      if (resp.success && resp.data) {
        handleChangeDataBy(
          { id_override: selectedFrequencyAssignment.id_override },
          { loading: false, heatmap: resp.data },
        )
      } else {
        toast.error(resp.message)
        handleChangeDataBy(
          { id_override: selectedFrequencyAssignment.id_override },
          { loading: false, heatmap: selectedFrequencyAssignment.heatmap },
        )
      }
    } else {
      handleChangeDataBy(
        { id_override: selectedFrequencyAssignment.id_override },
        { ...updatedPoint },
      )
    }
  }

  const handleAct = (value: ActionTypes) => {
    setActionType(value)

    switch (value) {
      case ActionTypes.MOVE:
        setIsAdding(false)
        setIsMoving(true)
        setIsManipulatingPoints(false)
        break
      case ActionTypes.ADD:
        setIsMoving(false)
        setIsAdding(true)
        setIsManipulatingPoints(false)
        break
      case ActionTypes.POINTS:
        setIsMoving(false)
        setIsAdding(false)
        setIsManipulatingPoints(true)
        break
      case ActionTypes.NONE:
        setIsMoving(false)
        setIsAdding(false)
        setIsManipulatingPoints(false)
        break
      default:
        break
    }
  }

  const handleAddCustomPoint = (newPoint: ICustomPoint) => {
    setCustomPoints((prev) => [...prev, newPoint])
  }

  const handleResetLocation = () => {
    const heatmapParams = selectedFrequencyAssignment.heatmapParams

    setIsMoving(false)
    handleChangeDataBy(
      { id_override: selectedFrequencyAssignment.id_override },
      {
        ...selectedFrequencyAssignment,
        heatmapParams: {
          ...heatmapParams,
          transmitter: {
            ...heatmapParams.transmitter,
            lat: selectedFrequencyAssignment.initialLat,
            lon: selectedFrequencyAssignment.initialLong,
          },
        },
        latitude: selectedFrequencyAssignment.initialLat,
        longitude: selectedFrequencyAssignment.initialLong,
      },
    )
    handleMoveDefaultPoints({
      lat: selectedFrequencyAssignment.initialLat,
      lng: selectedFrequencyAssignment.initialLong,
    })
  }

  const handleHeatmapParamsChange = (updatedParams: IHeatmapParams) => {
    handleChangeDataBy(
      { id_override: selectedFrequencyAssignment.id_override },
      { ...selectedFrequencyAssignment, heatmapParams: updatedParams },
    )
  }

  const handleDragMarker = (center: { lat: number; lng: number }) => {
    const heatmapParams = selectedFrequencyAssignment.heatmapParams

    handleChangeDataBy(
      {
        id_override: selectedFrequencyAssignment.id_override,
      },
      {
        ...selectedFrequencyAssignment,
        heatmapParams: {
          ...heatmapParams,
          transmitter: {
            ...heatmapParams.transmitter,
            lat: center.lat,
            lon: center.lng,
          },
        },
        latitude: center.lat,
        longitude: center.lng,
        heatmap: null,
      },
    )

    if (isDefaultPointsAdded) {
      handleMoveDefaultPoints(center)
    }

    if (showRadius) {
      setPosition([center.lat, center.lng])
    }
  }

  const handleMoveDefaultPoints = (center: { lat: number; lng: number }) => {
    const movedPoints = customPoints.map((point) => {
      const newLat =
        point.latitude + center.lat - selectedFrequencyAssignment.latitude
      const newLong =
        point.longitude + center.lng - selectedFrequencyAssignment.longitude

      return {
        ...point,
        heatmap: undefined,
        showHeatmap: false,
        latitude: newLat,
        longitude: newLong,
      }
    })

    setCustomPoints(movedPoints)
  }

  //Creates custom points at the edge for each direction(North, Northwest etc)
  const handleAddDefaultPoints = () => {
    let index = 1
    let direction = 0
    let previousPointCoords: number[] = []

    while (direction !== 405) {
      const { lat, lon } = calculateDestination(
        selectedFrequencyAssignment.latitude,
        selectedFrequencyAssignment.longitude,
        selectedFrequencyAssignment.exercise_request_location_radius,
        direction,
      )

      if (direction === 0) {
        direction += 45
        previousPointCoords = [lat, lon]
        continue
      }

      const radius =
        calculateDistance(
          lat,
          lon,
          previousPointCoords[0],
          previousPointCoords[1],
        ) / 2

      handleAddCustomPoint({
        radius,
        id: selectedFrequencyAssignment.id_override + index,
        latitude: lat,
        longitude: lon,
        heatmap: undefined,
        showHeatmap: false,
        heatmapParams: prepareHeatmapParams({
          lat,
          lon,
          frq: selectedFrequencyAssignment.exercise_request_assigned_frequency_value,
          rad: radius,
        }),
        selected: false,
        showRadius: false,
        exercise_request_assigned_frequency_value:
          selectedFrequencyAssignment.exercise_request_assigned_frequency_value,
      })

      direction += 45
      previousPointCoords = [lat, lon]
      index++
    }

    setIsDefaultPointsAdded(true)
  }

  const handleCustomPointSelect = (pointId: string) => {
    setLastSelectedPoint(pointId)
    setCustomPoints(
      customPoints.map((elem) =>
        elem.id === pointId
          ? { ...elem, selected: !elem.selected }
          : { ...elem },
      ),
    )
  }

  const handleCustomPointsRemove = () => {
    setCustomPoints([])
    setIsDefaultPointsAdded(false)
  }

  useEffect(() => {
    if (!isSelected) {
      setIsMoving(false)
    }
  }, [isSelected])

  useEffect(() => {
    if (isDragging && isDefaultPointsAdded) {
      setCustomPoints([])
      setShowHeatmap(false)
      setShowHeatmapSimulation(false)
    }
  }, [isDragging])

  useEffect(() => {
    setPosition([
      selectedFrequencyAssignment.latitude,
      selectedFrequencyAssignment.longitude,
    ])
  }, [
    selectedFrequencyAssignment.latitude,
    selectedFrequencyAssignment.longitude,
  ])

  if (!selectedFrequencyAssignment) return null

  return (
    <>
      {!isFixed && isMoving ? (
        <DraggableMarker
          center={position}
          draggable
          setDragging={setIsDragging}
          handleClick={handleMarkerClick}
          handlePositionChange={handleDragMarker}
        >
          <Tooltip>
            <PointDetails point={selectedFrequencyAssignment} />
          </Tooltip>
        </DraggableMarker>
      ) : (
        <Marker
          position={position}
          eventHandlers={{ click: handleMarkerClick }}
        >
          <Tooltip>
            <PointDetails point={selectedFrequencyAssignment} />
          </Tooltip>
        </Marker>
      )}

      {isSelected && (
        <EmitterDrawer
          isFixed={isFixed}
          points={points}
          handleAct={handleAct}
          actionType={actionType}
          showRadius={showRadius}
          showHeatmap={showHeatmap}
          customPoints={customPoints}
          setShowRadius={setShowRadius}
          onPointSelect={handleCustomPointSelect}
          setShowHeatmap={setShowHeatmap}
          setCustomPoints={setCustomPoints}
          simulationLoading={simulationLoading}
          setIsPopupHovered={setIsPopupHovered}
          handleSelectChange={handleSelectChange}
          setIsEditModalOpen={setIsEditModalOpen}
          handleResetLocation={handleResetLocation}
          isManipulatingPoints={isManipulatingPoints}
          isDefaultPointsAdded={isDefaultPointsAdded}
          showHeatmapSimulation={showHeatmapSimulation}
          handleAddDefaultPoints={handleAddDefaultPoints}
          setShowHeatmapSimulation={setShowHeatmapSimulation}
          handleCustomPointsRemove={handleCustomPointsRemove}
          selectedFrequencyAssignment={selectedFrequencyAssignment}
          setIsHeatmapParamsModalOpen={setIsHeatmapParamsModalOpen}
        />
      )}

      {showRadius && !isDragging && selectedFrequencyAssignment && position && (
        <Circle
          center={position}
          radius={
            selectedFrequencyAssignment.exercise_request_location_radius * 1000
          }
        />
      )}

      <HeatmapComponent
        id={selectedFrequencyAssignment.id_override}
        type="heatmap"
        heatmap={selectedHeatmap}
        showHeatmap={showHeatmap}
        heatmapLoadingStatus={heatmapLoadingStatus}
        setHeatmapLoadingStatus={setHeatmapLoadingStatus}
      />
      <HeatmapComponent
        id={selectedFrequencyAssignment.id_override}
        type="simulation"
        heatmap={heatmapSimulation}
        showHeatmap={showHeatmapSimulation}
        heatmapLoadingStatus={heatmapLoadingStatus}
        setHeatmapLoadingStatus={setHeatmapLoadingStatus}
      />

      {selectedFrequencyAssignment && (
        <UpdateModal
          point={selectedFrequencyAssignment}
          onUpdate={handleUpdate}
          isOpen={isEditModalOpen}
          onClose={() => setIsEditModalOpen(false)}
        />
      )}

      {customPoints.map((point) => (
        <CustomPoint
          key={point.id}
          point={point}
          onSelect={handleCustomPointSelect}
          setPoints={setCustomPoints}
        />
      ))}

      {selectedFrequencyAssignment.heatmapParams && (
        <HeatmapSettingsModal
          initialValues={selectedFrequencyAssignment.heatmapParams}
          onUpdate={handleHeatmapParamsChange}
          isOpen={isHeatmapParamsModalOpen}
          onClose={() => setIsHeatmapParamsModalOpen(false)}
        />
      )}
    </>
  )
}

export default memo(MapEntity)
