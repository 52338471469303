import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Box,
  Stack,
  Select,
  Checkbox,
  MenuItem,
  Typography,
  InputLabel,
  FormControl,
  SelectChangeEvent,
} from '@mui/material'
import cn from 'classnames'
import Button from '@mui/material/Button'
import FileSaver from 'file-saver'
import { toast } from 'react-toastify'
import ArrowButton from '@mui/icons-material/ArrowCircleRightOutlined'
import { ActionTypes } from '../map-entity'
import { useEmitters } from '../../../contexts/emitters.context'
import { analyzePoints, createHeatmap } from '../../../services/cloudRF.service'
import { IResponse } from '../../../types/response.type'
import { ICustomPoint } from '../../../types/emitter.type'
import prepareHeatmapParams from '../../../utils/prepareAreaRequestData'
import { getAffectedCountries } from '../../../utils/functions'
import { IHeatmapData, IPathData } from '../../../types/cloudRF.type'
import { IFrequencyAssignmentWithCoordinates } from '../../../types/exercise.type'

import styles from './styles.module.scss'

interface IProps {
  points: IFrequencyAssignmentWithCoordinates[]
  isFixed: boolean
  handleAct: (value: ActionTypes) => void
  actionType: string
  showRadius: boolean
  showHeatmap: boolean
  customPoints: ICustomPoint[]
  onPointSelect: (pointId: string) => void
  setShowRadius: Dispatch<SetStateAction<boolean>>
  setShowHeatmap: Dispatch<SetStateAction<boolean>>
  setCustomPoints: Dispatch<SetStateAction<ICustomPoint[]>>
  simulationLoading: boolean
  setIsPopupHovered: Dispatch<SetStateAction<boolean>>
  setIsEditModalOpen: Dispatch<SetStateAction<boolean>>
  handleSelectChange: (event: SelectChangeEvent<string>) => void
  handleResetLocation: () => void
  isManipulatingPoints: boolean
  isDefaultPointsAdded: boolean
  showHeatmapSimulation: boolean
  handleAddDefaultPoints: () => void
  handleCustomPointsRemove: () => void
  setShowHeatmapSimulation: Dispatch<SetStateAction<boolean>>
  setIsHeatmapParamsModalOpen: Dispatch<SetStateAction<boolean>>
  selectedFrequencyAssignment: IFrequencyAssignmentWithCoordinates
}

const EmitterDrawer: React.FC<IProps> = ({
  points,
  isFixed,
  handleAct,
  actionType,
  showRadius,
  showHeatmap,
  customPoints,
  onPointSelect,
  setShowRadius,
  setShowHeatmap,
  setCustomPoints,
  simulationLoading,
  setIsPopupHovered,
  handleSelectChange,
  setIsEditModalOpen,
  handleResetLocation,
  isDefaultPointsAdded,
  isManipulatingPoints,
  showHeatmapSimulation,
  handleAddDefaultPoints,
  handleCustomPointsRemove,
  setShowHeatmapSimulation,
  setIsHeatmapParamsModalOpen,
  selectedFrequencyAssignment,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const [pathData, setPathData] = useState<IPathData | null>(null)
  const [showPointsHeatmaps, setShowPointsHeatmaps] = useState(false)
  const [showPointsRadiuses, setShowPointsRadiuses] = useState(false)

  const { fetchHeatmapForEmitter } = useEmitters()

  const handleShowRadius = (show: boolean, pointId?: string) => {
    if (pointId) {
      setCustomPoints((prev) =>
        prev.map((elem) =>
          elem.id === pointId ? { ...elem, showRadius: show } : elem,
        ),
      )

      return
    }

    if (isManipulatingPoints) {
      setCustomPoints((prev) =>
        prev.map((elem) =>
          elem.selected ? { ...elem, showRadius: show } : elem,
        ),
      )
      setShowPointsRadiuses(show)
    } else {
      setShowRadius(show)
    }
  }

  const fetchHeatmap = async (point?: ICustomPoint) => {
    if (point) {
      let isNewHeatmapNeeded = !point.heatmap

      if (point.heatmap) {
        const heatmapBounds = point.heatmap.bounds
        const heatmapLat = Math.round(
          ((heatmapBounds[0] + heatmapBounds[2]) / 2) * 1000,
        )
        const heatmapLong = Math.round(
          ((heatmapBounds[1] + heatmapBounds[3]) / 2) * 1000,
        )
        const emitterLat = Math.round(point.latitude * 1000)
        const emitterLong = Math.round(point.longitude * 1000)

        if (heatmapLat !== emitterLat || heatmapLong !== emitterLong) {
          isNewHeatmapNeeded = true
        }
      }

      if (point && isNewHeatmapNeeded && point.radius) {
        const result = await createHeatmap(
          {
            id_override: point.id,
            lat: point.latitude,
            lon: point.longitude,
            frq: point.exercise_request_assigned_frequency_value,
            rad: point.radius,
            txw: point.power || 1,
            alt: point.elevation || 1,
          },
          point.heatmapParams,
        )

        let affectedCountries: Array<{ country: string; flag: string }> =
          getAffectedCountries(result.data)

        setCustomPoints((prev) =>
          prev.map((elem) =>
            elem.id === point.id
              ? { ...elem, heatmap: result.data, affectedCountries }
              : elem,
          ),
        )
      }

      return
    }

    /*
      This block will fetch heatmaps for all the selected custom points
      It's set to fetch them with 5 second delays, 
      but it will cause rate limit error, for 3 and more selected points.
      We are planning to migrate away from CloudRF,
      so I think we can leave it as it is now.
    */
    if (isManipulatingPoints) {
      const heatmapRequests: Promise<IResponse<IHeatmapData>>[] = []
      const selectedPoints = customPoints.filter((point) => point.selected)

      selectedPoints.forEach((point, index) => {
        let isNewHeatmapNeeded = !point.heatmap

        if (point.heatmap) {
          const heatmapBounds = point.heatmap.bounds
          const heatmapLat = Math.round(
            ((heatmapBounds[0] + heatmapBounds[2]) / 2) * 1000,
          )
          const heatmapLong = Math.round(
            ((heatmapBounds[1] + heatmapBounds[3]) / 2) * 1000,
          )
          const emitterLat = Math.round(point.latitude * 1000)
          const emitterLong = Math.round(point.longitude * 1000)

          if (heatmapLat !== emitterLat && heatmapLong !== emitterLong) {
            isNewHeatmapNeeded = true
          }
        }

        if (point && isNewHeatmapNeeded && point.radius && !point.loading) {
          const requestPromise = new Promise<IResponse<IHeatmapData>>(
            (resolve) => {
              setTimeout(async () => {
                const result = await createHeatmap(
                  {
                    id_override: point.id,
                    lat: point.latitude,
                    lon: point.longitude,
                    frq: point.exercise_request_assigned_frequency_value,
                    rad: point.radius,
                    txw: point.power || 1,
                    alt: point.elevation || 1,
                  },
                  point.heatmapParams,
                )
                resolve(result)
              }, index * 5000) // 5-second delay per request
            },
          )

          heatmapRequests.push(requestPromise)
        }
      })

      const results = await Promise.all(heatmapRequests)

      results.forEach((result) => {
        if (result.success && result.data) {
          let affectedCountries: Array<{ country: string; flag: string }> =
            getAffectedCountries(result.data)

          setCustomPoints((prev) =>
            prev.map((elem) =>
              result.data && elem.id === result.data.id_override
                ? { ...elem, heatmap: result.data, affectedCountries }
                : elem,
            ),
          )
        }
      })
    } else {
      let isNewHeatmapNeeded = !selectedFrequencyAssignment.heatmap

      if (selectedFrequencyAssignment.heatmap) {
        const heatmapBounds = selectedFrequencyAssignment.heatmap.bounds
        const heatmapLat = Math.round(
          ((heatmapBounds[0] + heatmapBounds[2]) / 2) * 1000,
        )
        const heatmapLong = Math.round(
          ((heatmapBounds[1] + heatmapBounds[3]) / 2) * 1000,
        )
        const emitterLat = Math.round(
          selectedFrequencyAssignment.latitude * 1000,
        )
        const emitterLong = Math.round(
          selectedFrequencyAssignment.longitude * 1000,
        )

        if (heatmapLat !== emitterLat && heatmapLong !== emitterLong) {
          isNewHeatmapNeeded = true
        }
      }

      if (
        selectedFrequencyAssignment &&
        isNewHeatmapNeeded &&
        selectedFrequencyAssignment.exercise_request_location_radius &&
        !selectedFrequencyAssignment.loading
      ) {
        fetchHeatmapForEmitter({
          id_override: selectedFrequencyAssignment.id_override,
        })
      }
    }
  }

  const handleAnalyzePoints = async () => {
    const selectedPoints = customPoints.filter((point) => point.selected)

    if (selectedPoints.length !== 2) {
      toast('Please choose 2 points for analyzes')
      return
    }

    const firstPoint = selectedPoints[0]
    const secondPoint = selectedPoints[1]

    const path = await analyzePoints(
      prepareHeatmapParams(
        {
          alt: firstPoint.elevation,
          txw: firstPoint.power,
          frq: firstPoint.exercise_request_assigned_frequency_value,
          lat: firstPoint.latitude,
          lon: firstPoint.longitude,
          rad: firstPoint.radius,
        },
        {
          rxg: 3,
          rxs: -100,
          alt: 1,
          lat: secondPoint.latitude,
          lon: secondPoint.longitude,
        },
      ),
    )

    if (path.data) {
      setPathData(path.data)
    }
  }

  const handleExport = async (format: 'kml' | 'kmz') => {
    if (
      selectedFrequencyAssignment === null ||
      !selectedFrequencyAssignment.heatmap?.kmz
    )
      return

    try {
      const blob = await (
        await fetch(selectedFrequencyAssignment.heatmap.kmz)
      ).blob()
      FileSaver.saveAs(
        blob,
        `${selectedFrequencyAssignment.id_override}.${format}`,
      )
    } catch (error) {
      console.error(`Error during ${format} export: `, error)
    }
  }

  const handleDownloadAnalyzes = async () => {
    if (!pathData) return

    try {
      const blob = await (await fetch(pathData['Chart image'])).blob()
      FileSaver.saveAs(blob, `${selectedFrequencyAssignment.id_override}.png`)
    } catch (error) {
      console.error(`Error during PNG export: `, error)
    }
  }

  const handleHeatmapClick = (show: boolean, point?: ICustomPoint) => {
    if (show) {
      fetchHeatmap(point)
    }

    if (!point && isManipulatingPoints) {
      setCustomPoints((prev) =>
        prev.map((elem) =>
          elem.selected ? { ...elem, showHeatmap: show } : elem,
        ),
      )
      setShowPointsHeatmaps(show)
    } else if (point) {
      setCustomPoints((prev) =>
        prev.map((elem) =>
          elem.id === point.id ? { ...elem, showHeatmap: show } : elem,
        ),
      )
    } else {
      setShowHeatmap(show)
    }
  }

  useEffect(() => {
    setPathData(null)
  }, [customPoints.filter((point) => point.selected).length])

  return (
    <div
      className={`${styles.sidebar} sub-container`}
      onMouseOver={() => setIsPopupHovered(true)}
      onMouseLeave={() => setIsPopupHovered(false)}
    >
      <div className={styles.container}>
        <div className={cn(styles.box, { [styles.closed]: !isOpen })}>
          <div className={styles.spexPinPopup}>
            <Stack>
              <Typography>
                {selectedFrequencyAssignment.exercise_name}
              </Typography>
              <Select
                MenuProps={{ style: { zIndex: 6010 } }}
                value={selectedFrequencyAssignment.id_override}
                onChange={handleSelectChange}
                disabled={
                  selectedFrequencyAssignment.loading || simulationLoading
                }
              >
                {points.map((point) => (
                  <MenuItem key={point.id_override} value={point.id_override}>
                    {point.id_override}
                  </MenuItem>
                ))}
              </Select>
              {(selectedFrequencyAssignment.loading || simulationLoading) && (
                <span style={{ marginLeft: '5px' }}>Loading...</span>
              )}
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: 10,
                  marginTop: 10,
                }}
              >
                <div style={{ display: 'flex', gap: 12 }}>
                  {!isManipulatingPoints ? (
                    <Button
                      className={styles.btn}
                      variant="outlined"
                      onClick={() => handleShowRadius(!showRadius)}
                    >
                      {showRadius ? 'Hide' : 'Show'} Radius
                    </Button>
                  ) : (
                    <Button
                      className={styles.btn}
                      variant="outlined"
                      onClick={() => handleShowRadius(!showPointsRadiuses)}
                    >
                      {showPointsRadiuses ? 'Hide' : 'Show'} Radius
                    </Button>
                  )}
                  {!isManipulatingPoints ? (
                    <Button
                      className={styles.btn}
                      variant="outlined"
                      onClick={() => handleHeatmapClick(!showHeatmap)}
                    >
                      {showHeatmap ? 'Hide' : 'Show'} Heatmap
                    </Button>
                  ) : (
                    <Button
                      variant="outlined"
                      onClick={() => handleHeatmapClick(!showPointsHeatmaps)}
                    >
                      {showPointsHeatmaps ? 'Hide' : 'Show'} Heatmap
                    </Button>
                  )}
                  <Button
                    variant="outlined"
                    onClick={() => setShowHeatmapSimulation((prev) => !prev)}
                  >
                    {showHeatmapSimulation ? 'Hide' : 'Show'} Heatmap Simulation
                  </Button>
                </div>
              </div>
              <div>
                <p>
                  <strong>ID Override:</strong>{' '}
                  {selectedFrequencyAssignment.id_override}
                </p>
                <p>
                  <strong>Exercise Name:</strong>{' '}
                  {selectedFrequencyAssignment.exercise_name}
                </p>
                <div>
                  <div style={{ display: 'flex', gap: 12 }}>
                    <Button
                      variant="outlined"
                      onClick={() => setIsEditModalOpen(true)}
                    >
                      Edit record
                    </Button>
                    <Button
                      variant="outlined"
                      onClick={() => setIsHeatmapParamsModalOpen(true)}
                    >
                      Change heatmap params
                    </Button>
                  </div>
                  <p>Export as:</p>
                  <div style={{ display: 'flex', gap: 12 }}>
                    <Button
                      variant="outlined"
                      disabled={!selectedFrequencyAssignment.heatmap?.kmz}
                      onClick={() => handleExport('kml')}
                    >
                      KML
                    </Button>
                    <Button
                      variant="outlined"
                      disabled={!selectedFrequencyAssignment.heatmap?.kmz}
                      onClick={() => handleExport('kmz')}
                    >
                      KMZ
                    </Button>
                  </div>
                </div>
              </div>
            </Stack>
            <Box className={styles.actionsContainer}>
              {!isFixed && (
                <FormControl>
                  <InputLabel id="actions">Actions</InputLabel>
                  <Select
                    labelId="actions"
                    value={actionType}
                    onChange={(evt) =>
                      handleAct(evt.target.value as ActionTypes)
                    }
                    label="Actions"
                  >
                    <MenuItem value={ActionTypes.NONE}>None</MenuItem>
                    <MenuItem value={ActionTypes.MOVE}>Move Emitter</MenuItem>
                    <MenuItem value={ActionTypes.ADD}>
                      Add custom points
                    </MenuItem>
                    <MenuItem value={ActionTypes.POINTS}>
                      Manipulate points
                    </MenuItem>
                    <MenuItem value={ActionTypes.ANALYZE}>
                      Analyze Points
                    </MenuItem>
                  </Select>
                </FormControl>
              )}
              {selectedFrequencyAssignment.latitude !==
                selectedFrequencyAssignment.initialLat &&
                selectedFrequencyAssignment.longitude !==
                  selectedFrequencyAssignment.initialLong && (
                  <Button variant="outlined" onClick={handleResetLocation}>
                    {'Reset'}
                  </Button>
                )}
              <Box>
                {actionType === ActionTypes.ADD && !isDefaultPointsAdded && (
                  <Button onClick={handleAddDefaultPoints} variant="outlined">
                    Add default points
                  </Button>
                )}
                {isDefaultPointsAdded && (
                  <Button onClick={handleCustomPointsRemove}>
                    Remove points
                  </Button>
                )}
              </Box>
            </Box>
          </div>
          <Box
            className={cn(styles.arrowButton, { [styles.closed]: !isOpen })}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <ArrowButton className={styles.icon} />
          </Box>
          {actionType === ActionTypes.ANALYZE && (
            <Box>
              <Typography>Please choose 2 points to analyze</Typography>
              <Box sx={{ display: 'flex', gap: '10px' }}>
                <Button
                  onClick={handleAnalyzePoints}
                  disabled={
                    customPoints.filter((point) => point.selected).length !== 2
                  }
                >
                  Analyze
                </Button>
                {pathData && (
                  <Button onClick={handleDownloadAnalyzes}>
                    Download Analysis
                  </Button>
                )}
              </Box>
            </Box>
          )}
          {!!customPoints.length && <Typography>Manage Points</Typography>}
          <Box className={styles.customPoints}>
            {customPoints.map((point, index) => (
              <Box className={styles.customPointContainer} key={point.id}>
                <Typography className={styles.title}>
                  Point N{index + 1}
                </Typography>
                <Box className={styles.actions}>
                  <Checkbox
                    checked={point.selected}
                    onClick={() => onPointSelect(point.id)}
                  />
                  <Box className={styles.btns}>
                    <Button
                      className={styles.btn}
                      onClick={() =>
                        handleShowRadius(!point.showRadius, point.id)
                      }
                    >
                      {point.showRadius ? 'Hide' : 'Show'} Radius
                    </Button>
                    <Button
                      className={styles.btn}
                      onClick={() =>
                        handleHeatmapClick(!point.showHeatmap, point)
                      }
                    >
                      {point.showHeatmap ? 'Hide' : 'Show'} Heatmap
                    </Button>
                    <Button
                      className={styles.btn}
                      onClick={() =>
                        setCustomPoints((prev) =>
                          prev.map((elem) =>
                            elem.id === point.id
                              ? { ...elem, showHeatmapSettings: true }
                              : elem,
                          ),
                        )
                      }
                    >
                      Heatmap Settings
                    </Button>
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </div>
      </div>
    </div>
  )
}

export default EmitterDrawer
