import { FC } from 'react'
import {
  IDeviceAntenna,
  IExerciseRequestDeviceAntenna,
} from '../../../../types/equipment.type'
import { Box, Button, Typography } from '@mui/material'
import { SelectWithSearch } from '../../../shared/SearchSelect'

import styles from '../SectionLayout/styles.module.scss'

interface IProps {
  antennaInfo: IExerciseRequestDeviceAntenna
  deviceAntennas: IDeviceAntenna[]
  handleDelete: (id: number) => void
  handleChange: (id: number, key: string, value: string | number) => void
}

export const AntennaInfo: FC<IProps> = ({
  antennaInfo,
  deviceAntennas,
  handleChange,
  handleDelete,
}) => {
  return (
    <>
      <Box key={antennaInfo.id} className={styles.titleContainer}>
        <Typography className={styles.title}>Antenna Information</Typography>
        <Button
          variant="outlined"
          color="error"
          onClick={() => handleDelete(antennaInfo.id)}
        >
          Delete
        </Button>
      </Box>
      <Box className={styles.subSection}>
        <Box className={styles.fieldContainer}>
          <Typography className={styles.label}>Antenna Type:</Typography>
          <SelectWithSearch
            width={350}
            value={deviceAntennas
              .map((antenna) => ({
                label: `${antenna.nomaclature}: ${antenna.antenna_type} ${antenna.serial}`,
                value: antenna.id,
              }))
              .find((elem) => elem.value === antennaInfo.device_antenna_id)}
            label="Antenna Type"
            handleChange={(value) =>
              handleChange(antennaInfo.id, 'device_antenna_id', Number(value))
            }
            options={deviceAntennas.map((antenna) => ({
              value: antenna.id,
              label: `${antenna.nomaclature}: ${antenna.antenna_type} ${antenna.serial}`,
            }))}
          />
        </Box>
      </Box>
    </>
  )
}
