import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { IColumn, ITable } from '../types/requestId.type'
import { RequestIdEndpoints } from '../types/endpoint.type'

export const searchAllLocations = async (
  lat: number,
  long: number,
  radius: number,
): Promise<IResponse<number>> => {
  try {
    const data = await axiosInstance.get(RequestIdEndpoints.GET_LOCATIONS, {
      params: {
        lat,
        long,
        radius,
      },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getAllTables = async (): Promise<IResponse<ITable[]>> => {
  try {
    const data = await axiosInstance.get(RequestIdEndpoints.GET_TABLES)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getTableColumns = async (
  table: string,
): Promise<IResponse<IColumn[]>> => {
  try {
    const data = await axiosInstance.get(RequestIdEndpoints.GET_COLUMNS, {
      params: { table },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getColumnValues = async (
  table: string,
  column: string,
): Promise<IResponse<Array<string | number>>> => {
  try {
    const data = await axiosInstance.get(RequestIdEndpoints.GET_VALUES, {
      params: { table, column },
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
