import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import DashboardSidebar from '../../components/features/SpectrumManagerDashboard/Sidebar'

import styles from './styles.module.scss'

const SpectrumManagerDashboard = () => {
  return (
    <Box className={`${styles.spectrumManager} main-container`}>
      <DashboardSidebar />
      <Box className={`${styles.content} sub-container`}>
        <Outlet />
      </Box>
    </Box>
  )
}

export default SpectrumManagerDashboard
