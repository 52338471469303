import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { getAllExercises } from '../services/exercise-request-form.service'
import { getFrequencyAssignments } from '../services/frequency-assignment.service'
import { createHeatmap } from '../services/cloudRF.service'
import {
  IFrequencyAssignment,
  IFrequencyAssignmentWithCoordinates,
  IExercise,
} from '../types/exercise.type'
import { dmsToDecimal, getAffectedCountries } from '../utils/functions'
import convertFrequencyToMHz from '../utils/convertFrequencyToMHz'
import prepareHeatmapParams from '../utils/prepareAreaRequestData'

interface IEmittersContext {
  data: IFrequencyAssignmentWithCoordinates[][]
  filters: Array<{ key: keyof IFrequencyAssignment; value: string | number }>
  startDate: string | null
  endDate: string | null
  frequencyRange: { min: number; max: number }
  areUploadsVisible: boolean
  exercisesToFilter: IExercise[]
  getExercises: () => void
  findEmitterBy: (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => IFrequencyAssignmentWithCoordinates | undefined
  handleChangeDataBy: (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
    newData: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => void
  handleFilterChange: (
    index: number,
    key: keyof IFrequencyAssignment,
    value: string | number,
  ) => void
  handleFilterRemove: (index: number) => void
  handleDateRangeChange: (
    startDate: string | null,
    endDate: string | null,
  ) => void
  handleFrequencyRangechange: (value: { min: number; max: number }) => void
  clearFilters: () => void
  toggleUploadVisibility: () => void
  fetchHeatmapForEmitter: (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => void
  selectEmitterData: (oldIdOverride: string, newIdOverride: string) => void
}

const EmittersContext = createContext({} as IEmittersContext)

const groupByCoordinates = (data: IFrequencyAssignment[]) => {
  const coordinateGroups: Map<string, IFrequencyAssignmentWithCoordinates[]> =
    new Map()

  for (const item of data) {
    const latitude = dmsToDecimal(
      item.exercise_request_location_latitude_deg,
      item.exercise_request_location_latitude_min,
      item.exercise_request_location_latitude_sec,
      item.exercise_request_location_latitude_ns,
    )
    const longitude = dmsToDecimal(
      item.exercise_request_location_longitude_deg,
      item.exercise_request_location_longitude_min,
      item.exercise_request_location_longitude_sec,
      item.exercise_request_location_longitude_ew,
    )
    const key = `${latitude},${longitude}`

    if (!coordinateGroups.has(key)) {
      coordinateGroups.set(key, [])
    }
    // Add latitude and longitude to the item
    const updatedItem = {
      ...item,
      exercise_request_assigned_frequency_value: convertFrequencyToMHz(
        item.exercise_request_assigned_frequency_value,
      ),
      heatmap: null,
      latitude: latitude,
      longitude: longitude,
      initialLat: latitude,
      initialLong: longitude,
      heatmapParams: prepareHeatmapParams({
        alt: item.exercise_request_location_elevation || 1,
        txw: item.power,
        frq: convertFrequencyToMHz(
          item.exercise_request_assigned_frequency_value,
        ),
        lat: latitude,
        lon: longitude,
        rad: item.exercise_request_location_radius,
      }),
      exercise_end_date: dayjs(item.exercise_end_date),
      exercise_start_date: dayjs(item.exercise_start_date),
    }

    coordinateGroups.get(key)!.push(updatedItem)
  }

  // Convert the map to an array of arrays
  const newData = Array.from(coordinateGroups.values())
  newData.forEach((group) => {
    group[0].selected = true
  })
  return newData
}

const EmittersProvider = ({ children }: { children: ReactNode }) => {
  const [filters, setFilters] = useState<
    Array<{ key: keyof IFrequencyAssignment; value: string | number }>
  >([])
  const [data, setData] = useState<IFrequencyAssignmentWithCoordinates[][]>([])
  const [startDate, setStartDate] = useState<string | null>(null)
  const [endDate, setEndDate] = useState<string | null>(null)
  const [frequencyRange, setFrequencyRange] = useState({ min: 0, max: 10000 })
  const [areUploadsVisible, setAreUploadsVisible] = useState(false)
  const [exercisesToFilter, setExercisesToFilter] = useState<IExercise[]>([])

  const [requestQueue, setRequestQueue] = useState<
    Array<Partial<IFrequencyAssignmentWithCoordinates>>
  >([])
  const isProcessingQueue = useRef(false)

  const controller = useRef<AbortController | null>(null)

  const getExercises = async () => {
    controller.current?.abort()
    const abortController = new AbortController()
    controller.current = abortController

    const response = await getFrequencyAssignments({
      startDate,
      endDate,
      frequencyMin: frequencyRange.min,
      frequencyMax: frequencyRange.max,
      filters,
    })

    // TODO: error handling
    if (response.data) setData(groupByCoordinates(response.data))
  }

  const getExercisesToFilter = async () => {
    const response = await getAllExercises()
    // TODO: error handling
    if (response.data) setExercisesToFilter(response.data)
  }

  const handleChangeDataBy = (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
    newData: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => {
    setData((prev) =>
      prev.map((group) =>
        group.map((item) => {
          const isMatch = Object.entries(findBy).every(
            ([key, value]) =>
              item[key as keyof IFrequencyAssignmentWithCoordinates] === value,
          )
          return isMatch ? { ...item, ...newData } : item
        }),
      ),
    )
  }

  const processQueue = async () => {
    if (isProcessingQueue.current || requestQueue.length === 0) return
    isProcessingQueue.current = true
    const currentRequest = requestQueue[0]

    await fetchHeatmapForEmitter(currentRequest)

    setRequestQueue((prevQueue) => prevQueue.slice(1))
    isProcessingQueue.current = false
  }

  const addToQueue = (
    request: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => {
    const exists = requestQueue.some((existingRequest) => {
      return Object.entries(request).every(
        ([key, value]) =>
          existingRequest[key as keyof IFrequencyAssignmentWithCoordinates] ===
          value,
      )
    })

    if (!exists) {
      handleChangeDataBy(
        { id_override: request.id_override },
        { loading: true },
      )
      setRequestQueue((prev) => [...prev, request])
    }
  }

  const fetchHeatmapForEmitter = async (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
  ) => {
    const emitter = data
      .flat()
      .find((emitter) =>
        Object.entries(findBy).every(
          ([key, value]) =>
            emitter[key as keyof IFrequencyAssignmentWithCoordinates] === value,
        ),
      )

    if (!emitter) return

    handleChangeDataBy({ id_override: emitter.id_override }, { loading: true })

    const resp = await createHeatmap(
      {
        id_override: emitter.id_override,
        lat: emitter.latitude,
        lon: emitter.longitude,
        frq: emitter.exercise_request_assigned_frequency_value,
        rad: emitter.exercise_request_location_radius,
        txw: emitter.power,
        alt: emitter.exercise_request_location_elevation || 1,
      },
      emitter.heatmapParams,
    )

    if (resp.success && resp.data) {
      const affectedCountries = getAffectedCountries(resp.data)

      handleChangeDataBy(
        { id_override: emitter.id_override },
        { heatmap: resp.data, affectedCountries, loading: false },
      )
    } else {
      toast.error(resp.message)
    }
  }

  const findEmitterBy = (
    findBy: Partial<IFrequencyAssignmentWithCoordinates>,
  ): IFrequencyAssignmentWithCoordinates | undefined => {
    for (const group of data) {
      for (const item of group) {
        const isMatch = Object.entries(findBy).every(
          ([key, value]) =>
            item[key as keyof IFrequencyAssignmentWithCoordinates] === value,
        )
        if (isMatch) {
          return item
        }
      }
    }
    return undefined
  }

  const toggleUploadVisibility = () => setAreUploadsVisible((prev) => !prev)
  // Filters mutation
  const handleFilterChange = (
    index: number,
    key: keyof IFrequencyAssignment,
    value: string | number,
  ) => {
    const newFilters = [...filters]
    if (index >= newFilters.length) {
      newFilters.push({ key, value })
    } else {
      newFilters[index] = { key, value }
    }
    setFilters(newFilters)
  }

  const handleFilterRemove = (index: number) => {
    const newFilters = filters.filter((_, i) => i !== index)
    setFilters(newFilters)
  }

  const handleDateRangeChange = (
    startDate: string | null,
    endDate: string | null,
  ) => {
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const clearFilters = () => {
    setFilters([])
    setStartDate(null)
    setEndDate(null)
    setFrequencyRange({ min: 0, max: 10000 })
    getExercises()
  }

  const selectEmitterData = (oldIdOverride: string, newIdOverride: string) => {
    setData((prev) =>
      prev.map((group) =>
        group.map((el) => {
          const newEl = { ...el }

          if (newEl.id_override === oldIdOverride) {
            newEl.selected = false
          }
          if (newEl.id_override === newIdOverride) {
            newEl.selected = true
            if (!newEl.heatmap && newEl.exercise_request_location_radius) {
              addToQueue({ id_override: newIdOverride })
            }
          }

          return newEl
        }),
      ),
    )
  }

  useEffect(() => {
    getExercises()
    getExercisesToFilter()
  }, [])

  useEffect(() => {
    if (isProcessingQueue.current || requestQueue.length === 0) return
    processQueue()
  }, [requestQueue, isProcessingQueue.current])

  return (
    <EmittersContext.Provider
      value={{
        data,
        filters,
        startDate,
        endDate,
        frequencyRange,
        areUploadsVisible,
        exercisesToFilter,
        handleChangeDataBy,
        handleFilterChange,
        handleFilterRemove,
        handleDateRangeChange,
        findEmitterBy,
        handleFrequencyRangechange: setFrequencyRange,
        getExercises,
        clearFilters,
        toggleUploadVisibility,
        fetchHeatmapForEmitter: addToQueue,
        selectEmitterData,
      }}
    >
      {children}
    </EmittersContext.Provider>
  )
}

export const useEmitters = () => {
  const context = useContext(EmittersContext)
  if (context === undefined) {
    throw new Error('useEmitters must be used within an EmittersProvider')
  }
  return context
}

export default EmittersProvider
