import {
  memo,
  useMemo,
  useState,
  useRef,
  useEffect,
  SetStateAction,
  Dispatch,
} from 'react'
import * as L from 'leaflet'
import { Marker, Circle } from 'react-leaflet'
import { LatLngTuple } from 'leaflet'
import RedIcon from '../../../assets/images/point-icon-red.png'
import GreenIcon from '../../../assets/images/point-icon-green.png'
import { ICustomPoint } from '../../../types/emitter.type'
import HeatmapComponent from '../Heatmap'
import { IHeatmapLoadingStatus } from '../map-entity'
import HeatmapSettingsModal from '../map-entity/HeatmapSettingsModal'
import { IHeatmapParams } from '../../../types/cloudRF.type'

interface IProps {
  point: ICustomPoint
  onSelect: (id: string) => void
  setPoints: Dispatch<SetStateAction<ICustomPoint[]>>
}

const CustomPoint: React.FC<IProps> = ({ point, setPoints, onSelect }) => {
  const [heatmapLoadingStatus, setHeatmapLoadingStatus] =
    useState<IHeatmapLoadingStatus>({
      [point.id]: { heatmap: 0, simulation: 0 },
    })

  const LeafIcon = L.Icon.extend({
    options: {
      iconUrl: point.selected ? GreenIcon : RedIcon,
      iconSize: [30, 30],
    },
  })

  const blueIcon = new LeafIcon()

  // cancel timers when we unmount:
  const timeoutHandles = useRef<number[]>([])

  const handleMarkerClick = () => {
    onSelect(point.id)
  }

  const handleUpdateParams = (updatedParams: IHeatmapParams) => {
    setPoints((prev) =>
      prev.map((elem) =>
        elem.id === point.id ? { ...elem, heatmapParams: updatedParams } : elem,
      ),
    )
  }

  useEffect(() => {
    return () => {
      while (timeoutHandles.current.length) {
        const handle = timeoutHandles.current.pop()
        clearTimeout(handle)
      }
    }
  }, [])

  const position = useMemo<LatLngTuple>(
    () => [point.latitude, point.longitude],
    [point],
  )

  if (!point) return null

  return (
    <>
      <Marker
        position={position}
        eventHandlers={{ click: handleMarkerClick }}
        icon={blueIcon}
      />
      {point.showRadius && position && (
        <Circle center={position} radius={point.radius * 1000} />
      )}

      <HeatmapComponent
        id={point.id}
        type="heatmap"
        heatmap={point.heatmap}
        showHeatmap={point.showHeatmap}
        heatmapLoadingStatus={heatmapLoadingStatus}
        setHeatmapLoadingStatus={setHeatmapLoadingStatus}
      />

      <HeatmapSettingsModal
        isOpen={!!point.showHeatmapSettings}
        onClose={() =>
          setPoints((prev) =>
            prev.map((elem) =>
              elem.id === point.id
                ? { ...elem, showHeatmapSettings: false }
                : elem,
            ),
          )
        }
        onUpdate={handleUpdateParams}
        initialValues={point.heatmapParams}
      />
    </>
  )
}

export default memo(CustomPoint)
