import { useContext, useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import Toolbar from '../Toolbar'
import ERFModal from '../../Modals/ERFModal'
import SFAFDrawer from '../SFAFDrawer'
import TableComponent from '../../../shared/Table'
import { AuthContext } from '../../../../contexts/auth.context'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import { getSingleExerciseRequest } from '../../../../services/exercise-request-form.service'
import { IExerciseRequestDetails } from '../../../../types/spectrum-manager.type'
import { ExerciseRequestTableConfigs } from '../../../../utils/constants'
import {
  createAssignedFrequency,
  updateFrequencyRequest,
} from '../../../../services/spectrum-manager.service'
import { AppConfigContext } from '../../../../contexts/app-config.context'

import styles from './styles.module.scss'

const Requests = () => {
  const [completingItem, setCompletingItem] =
    useState<IExerciseRequestDetails | null>(null)
  const [isViewMode, setIsViewMode] = useState(false)
  const [selectedRequest, setSelectedRequest] =
    useState<IExerciseRequestDetails | null>(null)

  const {
    filterOptions,
    detailedExerciseRequests,
    setDetailedExerciseRequests,
    getDetailedExerciseRequests,
  } = useContext(SpectrumManagerContext)

  const { user } = useContext(AuthContext)
  const { isLightMode } = useContext(AppConfigContext)
  const { setSelectedExerciseRequest } = useContext(ExerciseRequestContext)

  const getExerciseRequest = async () => {
    if (completingItem) {
      const data = await getSingleExerciseRequest(completingItem.request_id)

      if (data.data) {
        const parsedRequest = {
          ...data.data,
          start_date: dayjs(data.data.start_date),
          end_date: dayjs(data.data.end_date),
        }
        setSelectedExerciseRequest(parsedRequest)
      }
    }
  }

  const handleView = (request: IExerciseRequestDetails) => {
    setIsViewMode(true)
    setSelectedRequest(request)
  }

  const handleClose = () => {
    setIsViewMode(false)
    setSelectedRequest(null)
  }

  const handleSave = async (request: IExerciseRequestDetails) => {
    const data = await updateFrequencyRequest(
      request.frequency_id,
      request.radius_306,
      request.radius_406,
      request.number_of_frequencies,
    )

    const updatedRequest = detailedExerciseRequests.find(
      (elem) => elem.frequency_id === request.frequency_id,
    )

    setDetailedExerciseRequests((prev) =>
      prev.map((elem) =>
        elem.frequency_id === request.frequency_id ? request : elem,
      ),
    )

    if (data.success && updatedRequest) {
      const freqDiff =
        request.number_of_frequencies - updatedRequest.number_of_frequencies

      for (let i = 0; i < freqDiff; i++) {
        await createAssignedFrequency(request.frequency_id)
      }
    }
  }

  useEffect(() => {
    getExerciseRequest()
  }, [completingItem])

  useEffect(() => {
    if (user) getDetailedExerciseRequests(user.id)
  }, [user, filterOptions])

  return (
    <Box
      className={styles.requests}
      style={{ backgroundColor: isLightMode ? 'white' : '#13222e' }}
    >
      <TableComponent
        data={detailedExerciseRequests}
        handleSave={handleSave}
        configs={ExerciseRequestTableConfigs}
        handleView={handleView}
        isLightMode={isLightMode}
        isIncomplete={true}
        handleSelect={setSelectedRequest}
        setIsCompleting={setCompletingItem}
        children={
          <Toolbar value={0} selectedRequest={selectedRequest} isDashboard />
        }
      />
      {completingItem && (
        <ERFModal
          isOpen={!!completingItem}
          onClose={() => setCompletingItem(null)}
          isLightMode={isLightMode}
          detailedExerciseRequest={completingItem}
        />
      )}
      {selectedRequest && (
        <SFAFDrawer
          isOpen={isViewMode}
          request={selectedRequest}
          handleClose={handleClose}
        />
      )}
    </Box>
  )
}

export default Requests
