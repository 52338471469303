import { useEffect } from 'react'
import { useMap } from 'react-leaflet'

const MapControl: React.FC<{ isPopupHovered: boolean }> = ({
  isPopupHovered,
}) => {
  const map = useMap()

  useEffect(() => {
    map.dragging[isPopupHovered ? 'disable' : 'enable']()
    map.scrollWheelZoom[isPopupHovered ? 'disable' : 'enable']()
    map.doubleClickZoom[isPopupHovered ? 'disable' : 'enable']()
  }, [isPopupHovered, map])

  return null
}

export default MapControl
