import { axiosInstance } from './axios.service'
import { IResponse } from '../types/response.type'
import { PlaybookEndpoints } from '../types/endpoint.type'
import { IPagination, IPlaybook } from '../types/playbook.type'

export const getAllPlaybook = async (
  search?: string,
  pagination?: IPagination,
): Promise<IResponse<IPlaybook[]>> => {
  try {
    const data = await axiosInstance.get(
      PlaybookEndpoints.GET_ALL + `?search=${search || ''}`,
      {
        params: pagination,
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const getOnePLaybook = async (
  id: string,
): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.get(PlaybookEndpoints.GET_ONE + id)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const createPlaybook = async (playbook: {
  name: string
}): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.post(PlaybookEndpoints.CREATE, playbook)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const updatePlaybook = async (
  playbook: IPlaybook,
  isCreating?: boolean,
): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.put(
      PlaybookEndpoints.UPDATE + playbook.id,
      { ...playbook, isCreating },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const deletePlaybook = async (
  id: number,
): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.delete(PlaybookEndpoints.DELETE + id)

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const downloadPdf = async (
  subject: string,
  requestId: number,
  locations: string[],
  dateRange: number[],
) => {
  try {
    const data = await axiosInstance.post(PlaybookEndpoints.DOWNLOAD_PDF, {
      subject,
      locations,
      requestId,
      dateRange,
    })

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const runPlaybook = async (
  playbookId: number,
  eventsRoom: string,
  user: string,
): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.get(
      PlaybookEndpoints.RUN + playbookId + `/${eventsRoom}`,
      {
        params: { user },
      },
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}

export const stopPlaybook = async (
  playbookId: number,
  eventsRoom: string,
): Promise<IResponse<IPlaybook>> => {
  try {
    const data = await axiosInstance.get(
      PlaybookEndpoints.STOP + playbookId + `/${eventsRoom}`,
    )

    return data.data
  } catch (err: any) {
    return {
      success: false,
      message: err?.response?.data?.message ?? err.message,
    }
  }
}
