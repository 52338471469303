import { useContext } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { AppConfigContext } from '../../../contexts/app-config.context'

import styles from './styles.module.scss'

const Loader = () => {
  return (
    <Box className={`${styles.loader} sub-container`}>
      <CircularProgress color="warning" />
    </Box>
  )
}

export default Loader
