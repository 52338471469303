import { useCallback, useContext, useEffect, useState } from 'react'
import {
  DataGrid,
  MuiEvent,
  GridColDef,
  GridRowModel,
  MuiBaseEvent,
  useGridApiRef,
  GridToolbarContainer,
  GridRowEditStopParams,
  GridRowEditStopReasons,
  GridColumnVisibilityModel,
} from '@mui/x-data-grid'
import {
  Box,
  Select,
  Button,
  MenuItem,
  TextField,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import DownloadIcon from '@mui/icons-material/Download'
import VisibilityIcon from '@mui/icons-material/Visibility'
import { IPagination } from '../../../types/playbook.type'
import ConfirmationModal from '../../features/Modals/ConfirmationModal'
import { AppConfigContext } from '../../../contexts/app-config.context'
import { getTableCellColor } from '../../../utils/functions'

import styles from './styles.module.scss'

const LOCAL_STORAGE_KEY = 'table_column_config'

const colors: { [key: string]: { background: string; color: string } } = {}

interface IProps<T> {
  data: T[]
  configs: {
    title: string
    items: {
      key: string
      isTag: boolean
      column: string
      width?: number
      items?: any[]
      isDate?: boolean
      editable?: boolean
    }[]
    columnWidth?: number
    isViewable: boolean
    isDeletable: boolean
    isSearchable?: boolean
    isNominating?: boolean
    isSelectable?: boolean
  }
  freqStatusSelect?: boolean
  children?: JSX.Element
  isLightMode: boolean
  isIncomplete?: boolean
  handleRefetch?: (pagination: IPagination, search?: string) => void
  importSfaf?: boolean
  handleView?: (item: any) => void
  handleEdit?: () => void
  handleSave?: (row: any) => void
  handleCreate?: () => void
  handleDelete?: (id: number) => void
  handleSelect?: (item: any) => void
  handleNominate?: (item: any) => void
  handleOpenSfaf?: () => void
  setIsCompleting?: (item: any) => void
}

const TableComponent = <T extends Record<string, any>>({
  data,
  configs,
  children,
  importSfaf,
  handleView,
  handleSave,
  handleCreate,
  handleSelect,
  handleDelete,
  isIncomplete,
  handleRefetch,
  handleNominate,
  handleOpenSfaf,
  setIsCompleting,
  freqStatusSelect,
}: IProps<T>) => {
  const [search, setSearch] = useState('')
  const [isDeleting, setIsDeleting] = useState(0)
  const [filteredData, setFilteredData] = useState<T[]>([])
  const [columnWidths, setColumnWidths] = useState<{ [key: string]: number }>(
    {},
  )
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({})

  const { isLightMode } = useContext(AppConfigContext)

  const apiRef = useGridApiRef()

  const saveSettingsToLocalStorage = () => {
    const settings = {
      columnVisibilityModel,
      columnWidths,
    }
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(settings))
  }

  const handleColumnVisibilityChange = (
    newModel: GridColumnVisibilityModel,
  ) => {
    setColumnVisibilityModel(newModel)
  }

  const handleColumnResize = (params: {
    colDef: GridColDef
    width: number
  }) => {
    setColumnWidths((prevWidths) => ({
      ...prevWidths,
      [params.colDef.field]: params.width,
    }))
  }

  const handleSearch = (term: string) => {
    const searchResults = data.filter((elem) =>
      Object.values(elem).some((value) =>
        String(value).toLocaleLowerCase().includes(term.toLowerCase()),
      ),
    )
    setSearch(term)
    setFilteredData(searchResults)
  }

  const handleViewClick = useCallback(
    (row: any) => {
      if (handleView) handleView(row)
    },
    [handleView],
  )

  const handleEditClick = useCallback(
    (row: any) => {
      if (setIsCompleting) setIsCompleting(row)
    },
    [setIsCompleting],
  )

  const handleRowEditStop = (
    params: GridRowEditStopParams,
    event: MuiEvent<MuiBaseEvent>,
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true
    }
  }

  const processRowUpdate = useCallback(
    (newRow: GridRowModel) => {
      const updatedRows = filteredData.map((row) =>
        row.id === newRow.id ? { ...row, ...newRow } : row,
      )
      setFilteredData(updatedRows)

      if (handleSave) {
        handleSave(newRow)
      }

      return newRow
    },
    [filteredData, handleSave],
  )

  const getColumns = useCallback(() => {
    const columns: GridColDef[] = configs.items.map((item) => ({
      field: item.key,
      headerName: item.column,
      sortable: true,
      editable: item.editable,
      headerAlign: item.isTag ? 'center' : 'left',
      width: columnWidths[item.key] || item.width || 170,
      renderCell: (params) => {
        let value = params.row[item.key]

        if (item.isTag && value) {
          const color = getTableCellColor(colors, value, isLightMode)
          if (!colors[value]) colors[value] = color

          return (
            <Box className={styles.tagWrapper}>
              <Tooltip title={value} arrow>
                <Box
                  className={styles.tag}
                  sx={{
                    backgroundColor: color.background,
                  }}
                >
                  <Typography
                    sx={{ color: color.color }}
                    className={styles.text}
                  >
                    {value}
                  </Typography>
                </Box>
              </Tooltip>
            </Box>
          )
        }

        value = params.value

        if (item.isDate) {
          value = value.format('YYYY-MM-DD')
        }

        return value
      },
    }))

    if (configs.isNominating) {
      columns.unshift({
        field: 'nominate',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        align: 'center',
        width: 70,
        renderCell: (params) => {
          return (
            <IconButton
              onClick={() => handleNominate && handleNominate(params.row)}
            >
              <AddIcon />
            </IconButton>
          )
        },
      })
    }

    if (configs.isViewable) {
      columns.unshift({
        field: 'view',
        align: 'center',
        width: isIncomplete ? 110 : 70,
        disableColumnMenu: true,
        sortable: false,
        headerName: '',
        renderCell: (params) => {
          return (
            <>
              <IconButton onClick={() => handleViewClick(params.row)}>
                <VisibilityIcon />
              </IconButton>
              {isIncomplete && (
                <IconButton onClick={() => handleEditClick(params.row)}>
                  <EditIcon />
                </IconButton>
              )}
            </>
          )
        },
      })
    }

    if (configs.isDeletable && handleDelete) {
      columns.push({
        field: 'delete',
        align: 'center',
        headerName: '',
        sortable: false,
        disableColumnMenu: true,
        width: 70,
        renderCell: (params) => {
          return (
            <IconButton onClick={() => setIsDeleting(params.row.id)}>
              <DeleteIcon sx={{ color: 'red' }} />
            </IconButton>
          )
        },
      })
    }

    return columns
  }, [
    configs,
    handleNominate,
    handleView,
    handleDelete,
    setIsDeleting,
    isIncomplete,
    setIsCompleting,
    columnWidths,
  ])

  function CustomToolbar() {
    if (apiRef) {
      return (
        <GridToolbarContainer>
          <IconButton
            onClick={() => apiRef.current && apiRef.current.exportDataAsCsv()}
          >
            <DownloadIcon />
          </IconButton>
        </GridToolbarContainer>
      )
    } else {
      return null
    }
  }

  useEffect(() => {
    saveSettingsToLocalStorage()
  }, [columnVisibilityModel, columnWidths])

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  useEffect(() => {
    const savedSettings = localStorage.getItem(LOCAL_STORAGE_KEY)

    if (savedSettings) {
      const { columnVisibilityModel, columnWidths } = JSON.parse(savedSettings)
      setColumnVisibilityModel(columnVisibilityModel || {})
      setColumnWidths(columnWidths || {})
    }
  }, [])

  return (
    <Box className={styles.tableComponent}>
      <Box className={styles.titleContainer}>
        {handleCreate && (
          <Button variant="outlined" onClick={handleCreate}>
            Create
          </Button>
        )}
        {children}
      </Box>
      {configs.isSearchable && (
        <Box className={styles.searchContainer}>
          <TextField
            className={styles.search}
            sx={{ maxWidth: importSfaf || freqStatusSelect ? 'auto' : 300 }}
            value={search}
            label="Search"
            onChange={(evt) => handleSearch(evt.target.value)}
          />
          {importSfaf && (
            <Button
              className={styles.importBtn}
              variant="outlined"
              onClick={handleOpenSfaf}
            >
              Import
            </Button>
          )}
          {freqStatusSelect && (
            <Select
              defaultValue={'NOT_STARTED'}
              className={styles.select}
              onChange={(evt) =>
                handleRefetch &&
                handleRefetch({} as IPagination, String(evt.target.value))
              }
            >
              {['NOT_STARTED', 'ACCEPTED', 'APPROVED', 'DENIED'].map(
                (status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ),
              )}
            </Select>
          )}
        </Box>
      )}
      <Box className={styles.tableWrapper}>
        <Box className={styles.table}>
          <DataGrid
            className={styles.dataGrid}
            getRowId={(row) => row.tableId}
            rows={filteredData.map((elem, ind) => ({
              ...elem,
              tableId: configs.title === 'Requests' ? ind : elem.id,
            }))}
            apiRef={apiRef}
            columns={getColumns()}
            onColumnResize={handleColumnResize}
            columnVisibilityModel={columnVisibilityModel}
            onColumnVisibilityModelChange={handleColumnVisibilityChange}
            slots={{
              footer: CustomToolbar,
            }}
            processRowUpdate={processRowUpdate}
            slotProps={{
              baseButton: { sx: { mb: 1, border: '1px solid white' } },
            }}
            onRowEditStop={handleRowEditStop}
            rowHeight={35}
            columnHeaderHeight={35}
            onRowClick={(params) => handleSelect && handleSelect(params.row)}
            sx={{
              '& .MuiDataGrid-container--top [role=row]': {
                background: isLightMode ? '#fff' : 'rgb(19, 34, 46) !important',
                color: isLightMode ? '#000000' : '#fff',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                background: 'rgb(183 181 181 / 30%)',
              },
              '& .MuiDataGrid-row.Mui-selected:hover': {
                background: 'rgb(183 181 181 / 30%)',
              },
            }}
          />
        </Box>
      </Box>
      {!!isDeleting && (
        <ConfirmationModal
          color="error"
          text="Are you sure you want to delete this row"
          btnText="Delete"
          onConfirm={() => {
            setIsDeleting(0)
            handleDelete && handleDelete(isDeleting)
          }}
          onClose={() => setIsDeleting(0)}
        />
      )}
    </Box>
  )
}

export default TableComponent
