import { Box } from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { FC, useCallback, useEffect, useState } from 'react'

import styles from './styles.module.scss'

interface DateRangePickerProps {
  onDateRangeChange: (startDate: Dayjs | null, endDate: Dayjs | null) => void
  initialStartDate?: string
  initialEndDate?: string
}

const DateRangePicker: FC<DateRangePickerProps> = ({
  onDateRangeChange,
  initialStartDate,
  initialEndDate,
}) => {
  const [startDate, setStartDate] = useState<Dayjs | null>(null)
  const [endDate, setEndDate] = useState<Dayjs | null>(null)

  useEffect(() => {
    if (initialStartDate) {
      setStartDate(dayjs(initialStartDate, 'YYYY-MM-DD'))
    }
    if (initialEndDate) {
      setEndDate(dayjs(initialEndDate, 'YYYY-MM-DD'))
    }
  }, [initialStartDate, initialEndDate])

  const handleStartDateChange = useCallback(
    (newValue: Dayjs | null) => {
      setStartDate(newValue)
      onDateRangeChange(newValue, endDate)
    },
    [endDate, onDateRangeChange],
  )

  const handleEndDateChange = useCallback(
    (newValue: Dayjs | null) => {
      setEndDate(newValue)
      onDateRangeChange(startDate, newValue)
    },
    [startDate, onDateRangeChange],
  )

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className={styles.dateRangePicker}>
        <DatePicker
          label="Start Date"
          value={startDate}
          onChange={handleStartDateChange}
        />
        <DatePicker
          label="End Date"
          value={endDate}
          onChange={handleEndDateChange}
        />
      </Box>
    </LocalizationProvider>
  )
}

export default DateRangePicker
