import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react'
import dayjs from 'dayjs'
import {
  IExercise,
  IUsageTime,
  IStateRegion,
  IMajorFunction,
  IExerciseRequest,
  ILocationTemplate,
  IUsageTimeService,
  IExerciseRequestContext,
  IExerciseRequestLocation,
  IExerciseRequestFrequency,
} from '../types/exercise.type'
import {
  getAllLocations,
  getAllExercises,
  getAllUsageTimes,
  getAllFrequencies,
  getAllStateRegions,
  getAllMajorFunctions,
  getAllExerciseRequests,
  getAllLocationTemplates,
  getAllUsageTimeServices,
} from '../services/exercise-request-form.service'
import { IExerciseRequestEquipment } from '../types/equipment.type'
import { getAllEquipments } from '../services/equipment.service'
import { AuthContext } from './auth.context'
import { parseExerciseRequests } from '../utils/functions'

export const ExerciseRequestContext = createContext<IExerciseRequestContext>({
  exercises: [],
  usageTimes: [],
  stateRegions: [],
  handleReset: () => {},
  setExercises: () => {},
  majorFunctions: [],
  exerciseRequests: [],
  usageTimeServices: [],
  locationTemplates: [],
  getExerciseRequests: () => {},
  setExerciseRequests: () => {},
  selectedExerciseRequest: null,
  exerciseRequestLocations: [],
  exerciseRequestEquipments: [],
  exerciseRequestFrequencies: [],
  setSelectedExerciseRequest: () => {},
  setExerciseRequestLocations: () => {},
  getExerciseRequestEquipments: () => {},
  setExerciseRequestEquipments: () => {},
  getExerciseRequestFrequencies: () => {},
  setExerciseRequestFrequencies: () => {},
  selectedExerciseRequestLocation: null,
  selectedExerciseRequestFrequency: null,
  setSelectedExerciseRequestLocation: () => {},
  setSelectedExerciseRequestFrequency: () => {},
})

export const ExerciseRequestProvider = ({
  children,
}: {
  children: ReactNode
}) => {
  const [exercises, setExercises] = useState<IExercise[]>([])
  const [usageTimes, setUsageTimes] = useState<IUsageTime[]>([])
  const [stateRegions, setStateRegions] = useState<IStateRegion[]>([])
  const [exerciseRequests, setExerciseRequests] = useState<IExerciseRequest[]>(
    [],
  )
  const [majorFunctions, setMajorFunctions] = useState<IMajorFunction[]>([])
  const [locationTemplates, setLocationTemplates] = useState<
    ILocationTemplate[]
  >([])
  const [usageTimeServices, setUsageTimeServices] = useState<
    IUsageTimeService[]
  >([])
  const [selectedExerciseRequest, setSelectedExerciseRequest] =
    useState<IExerciseRequest | null>(null)
  const [exerciseRequestEquipments, setExerciseRequestEquipments] = useState<
    IExerciseRequestEquipment[]
  >([])
  const [exerciseRequestLocations, setExerciseRequestLocations] = useState<
    IExerciseRequestLocation[]
  >([])
  const [exerciseRequestFrequencies, setExerciseRequestFrequencies] = useState<
    IExerciseRequestFrequency[]
  >([])
  const [selectedExerciseRequestLocation, setSelectedExerciseRequestLocation] =
    useState<IExerciseRequestLocation | null>(null)
  const [
    selectedExerciseRequestFrequency,
    setSelectedExerciseRequestFrequency,
  ] = useState<IExerciseRequestFrequency | null>(null)

  const { user } = useContext(AuthContext)

  const getExercises = async () => {
    const data = await getAllExercises()

    if (data.success && data.data) {
      const parsedExercises = data.data.map((exercise) => ({
        ...exercise,
        start_date: dayjs(exercise.start_date).utc(true),
        end_date: dayjs(exercise.end_date).utc(true),
      }))

      setExercises(parsedExercises)
    }
  }

  const getExerciseRequests = async (
    type: 'my requests' | 'all unit requests',
    exercise_id: number | null,
  ) => {
    if (user) {
      const data = await getAllExerciseRequests(
        type === 'my requests' && user.id ? user.id : null,
        exercise_id,
        type === 'all unit requests' && user.operatingUnit
          ? user.operatingUnit?.id
          : null,
      )

      if (data.success && data.data) {
        const parsedRequests = parseExerciseRequests(data.data)

        setExerciseRequests(parsedRequests)
      }
    }
  }

  const handleReset = () => {
    setSelectedExerciseRequest(null)
    setSelectedExerciseRequestLocation(null)
    setSelectedExerciseRequestFrequency(null)
  }

  const getStateRegions = async () => {
    const data = await getAllStateRegions()

    if (data.success && data.data) {
      setStateRegions(data.data)
    }
  }

  const getLocationTemplates = async () => {
    const data = await getAllLocationTemplates()

    if (data.success && data.data) {
      setLocationTemplates(data.data)
    }
  }

  const getUsageTimes = async () => {
    const data = await getAllUsageTimes()

    if (data.success && data.data) {
      setUsageTimes(data.data)
    }
  }

  const getUsageTimeServices = async () => {
    const data = await getAllUsageTimeServices()

    if (data.success && data.data) {
      setUsageTimeServices(data.data)
    }
  }

  const getExerciseRequestLocations = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllLocations(selectedExerciseRequest.id)

      if (data.success && data.data) {
        setExerciseRequestLocations(data.data)
      }
    }
  }

  const getMajorFunctions = async () => {
    const data = await getAllMajorFunctions()

    if (data.success && data.data) {
      setMajorFunctions(data.data)
    }
  }

  const getExerciseRequestFrequencies = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllFrequencies(selectedExerciseRequest.id)

      if (data.success && data.data) {
        setExerciseRequestFrequencies(data.data)
      }
    }
  }

  const getExerciseRequestEquipments = async () => {
    if (selectedExerciseRequest) {
      const data = await getAllEquipments(selectedExerciseRequest.id)

      if (data.success && data.data) {
        setExerciseRequestEquipments(data.data)
      }
    }
  }

  useEffect(() => {
    if (!user) return

    getExerciseRequestLocations()
    getExerciseRequestEquipments()
    getExerciseRequestFrequencies()
  }, [user, selectedExerciseRequest])

  useEffect(() => {
    if (!user) return

    getExercises()
    getUsageTimes()
    getStateRegions()
    getMajorFunctions()
    getLocationTemplates()
    getUsageTimeServices()
  }, [user])

  return (
    <ExerciseRequestContext.Provider
      value={{
        exercises,
        usageTimes,
        stateRegions,
        handleReset,
        setExercises,
        majorFunctions,
        exerciseRequests,
        locationTemplates,
        usageTimeServices,
        getExerciseRequests,
        setExerciseRequests,
        selectedExerciseRequest,
        exerciseRequestLocations,
        exerciseRequestEquipments,
        setSelectedExerciseRequest,
        exerciseRequestFrequencies,
        setExerciseRequestLocations,
        setExerciseRequestEquipments,
        getExerciseRequestEquipments,
        setExerciseRequestFrequencies,
        getExerciseRequestFrequencies,
        selectedExerciseRequestLocation,
        selectedExerciseRequestFrequency,
        setSelectedExerciseRequestLocation,
        setSelectedExerciseRequestFrequency,
      }}
    >
      {children}
    </ExerciseRequestContext.Provider>
  )
}
