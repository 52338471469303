import { FC, useContext } from 'react'
import { ThemeProvider } from '@mui/material'
import { ThemeOptions, createTheme } from '@mui/material/styles'
import { AppConfigContext } from '../contexts/app-config.context'
import type {} from '@mui/x-data-grid/themeAugmentation'

const DarkTheme: ThemeOptions = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(19, 34, 46)',
          height: '100%',
          width: '100%',
        },
        cell: {
          color: '#fff',

          '&:focus': {
            outline: 'none',
          },

          '& input': {
            color: 'white',
          },
        },
        columnHeader: {
          '& div': {
            fontWeight: 600,
          },
        },
        footerContainer: {
          borderTop: '1px solid gray',
          color: '#fff',
          '& p': {
            color: '#fff',
            marginBottom: 0,
          },
          '& .MuiSelect-select': {
            color: '#fff',
          },

          '& svg': {
            color: 'white',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'gray',
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          borderColor: '#fff',
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: '#fff !important',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #303030 inset',
            WebkitTextFillColor: '#fff !important',
          },
          '& .MuiInput-underline': {
            borderBottomColor: '#fff !important',
          },
          '& .MuiOutlinedInput-root': {
            color: '#fff !important',
            borderColor: '#fff !important ',

            '&.Mui-focused fieldset': {
              borderColor: '#fff !important',
            },
          },
          '& fieldset': {
            borderColor: '#fff !important',
            color: '#fff !important',
          },
          '& label': {
            color: '#fff !important',
          },
          '& input': {
            color: '#fff !important',
          },
          '& div': {
            color: '#fff !important',
          },
          '& svg': {
            color: '#fff !important',
          },
          '& label.Mui-focused': {
            color: '#fff !important',
          },
          '& .Mui-disabled': {
            color: '#fff !important',
            WebkitTextFillColor: '#fff !important',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#fff !important',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#fff !important',
          },
        },
        paper: {
          color: '#fff',
          backgroundColor: '#04111a',
        },
        noOptions: {
          color: '#fff !important',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: '#fff',
          borderColor: '#fff',
          '& .MuiInput-underline': {
            borderBottomColor: '#fff',
          },
          '& .MuiOutlinedInput-root': {
            borderColor: '#fff',

            '&.Mui-focused fieldset': {
              borderColor: '#fff',
            },
          },
          '& fieldset': {
            borderColor: '#fff',
          },
          '& label': {
            color: '#fff',

            '&.Mui-disabled': {
              color: '#fff',
            },
          },
          '& div': {
            color: '#fff',
          },
          '& label.Mui-focused': {
            color: '#fff',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: '#fff',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: '#fff',
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'gray',
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: '#fff',
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 'none !important',
          color: '#fff',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:nth-of-type(3)': {
            backgroundColor: '#13222e',

            '& > div': {
              backgroundColor: '#13222e',
            },
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#13222e',
          '& div': { display: 'flex', justifyContent: 'center' },
          '& button': { color: '#fff' },
          '& button:hover': { color: '#fff' },
          '& button:focus': { color: '#fff' },
          '& button:active': { color: '#fff' },
          '& button.Mui-selected': { color: '#fff' },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          outline: 'none',
          backgroundColor: '#13222e',
          borderColor: '#fff',
          color: '#fff',

          '& svg': {
            color: 'white',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          '& div': {
            color: '#fff',
          },
          '& svg': {
            color: '#fff',
          },
          '& fieldset': {
            border: '1px solid #fff',
            backgroundColor: 'transparent',
          },
        },
        iconOutlined: {
          color: '#fff',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#fff',
          backgroundColor: '#13222e',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          div: {
            color: '#fff !important',
          },
          span: {
            color: '#fff !important',
          },
          button: {
            color: '#fff !important',
          },
        },
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
      paper: 'rgb(19, 34, 46)',
    },
  },
})

const LightTheme = createTheme({
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          height: '100%',
          width: '100%',
        },
        cell: {
          color: 'black',
          '&:focus': {
            outline: 'none',
          },
        },
        columnHeader: {
          backgroundColor: '#fff',
          color: 'black',

          '& div': {
            fontWeight: 600,
          },
        },
        'container--top': {
          background: 'black',
        },
        footerContainer: {
          borderTop: '1px solid gray',
          '& p': {
            marginBottom: 0,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: 'black',
          borderColor: 'black',
          '& .MuiInput-underline': {
            borderBottomColor: 'black',
          },
          '& .MuiOutlinedInput-root': {
            borderColor: 'black',

            '&.Mui-focused fieldset': {
              borderColor: 'black',
            },
          },
          '& fieldset': {
            borderColor: 'black',
          },
          '& label.Mui-focused': {
            color: 'black',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black',
          },
          '& .MuiInputBase-input.Mui-disabled': {
            WebkitTextFillColor: 'gray',
            color: '#fff',
            border: '1px solid #fff',

            '& .MuiInput-underline': {
              borderBottomColor: 'black',
            },
            '& .MuiOutlinedInput-root': {
              borderColor: 'black',

              '&.Mui-focused fieldset': {
                borderColor: 'black',
              },
            },
            '& fieldset': {
              borderColor: 'black',
            },
            '& label.Mui-focused': {
              color: 'black',
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: 'black',
            },
            '& .MuiFilledInput-underline:after': {
              borderBottomColor: 'black',
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: 'gray',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          outline: 'none',
          backgroundColor: 'rgb(22, 119, 56)',
          color: '#fff',

          '&:hover': {
            backgroundColor: 'rgb(22, 119, 56, 0.8)',
          },

          '& svg': {
            color: 'white',
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          '&::-webkit-inner-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
          '&::-webkit-outer-spin-button': {
            WebkitAppearance: 'none',
            margin: 0,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          outline: 'none !important',
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          color: 'black',
          outline: 'none',
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          outline: 'none',
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          '& > div:nth-of-type(3)': {
            backgroundColor: '#fff',

            '& > div': {
              backgroundColor: '#fff',
            },
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          '$ div': {
            color: 'black',
          },
          '$ svg': {
            color: 'black',
          },
          '& fieldset': {
            border: '1px solid black',
            backgroundColor: 'transparent',
          },
        },
        iconOutlined: {
          color: 'black',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          backgroundColor: '#fff',
          '& div': { display: 'flex', justifyContent: 'center' },
          '& button': { color: 'black' },
          '& button:hover': { color: 'rgb(22, 119, 56)' },
          '& button:focus': { color: 'rgb(22, 119, 56)' },
          '& button:active': { color: 'rgb(22, 119, 56)' },
          '& button.Mui-selected': { color: 'rgb(22, 119, 56)' },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          color: 'black !important',
          '&:-webkit-autofill': {
            WebkitBoxShadow: '0 0 0 100px #303030 inset',
            WebkitTextFillColor: 'black !important',
          },
          '& .MuiInput-underline': {
            borderBottomColor: 'black !important',
          },
          '& .MuiOutlinedInput-root': {
            color: 'black !important',
            borderColor: 'black !important ',

            '&.Mui-focused fieldset': {
              borderColor: 'black !important',
            },
          },
          '& fieldset': {
            borderColor: 'black !important',
            color: 'black !important',
          },
          '& label': {
            color: 'black !important',
          },
          '& input': {
            color: 'black !important',
          },
          '& div': {
            color: 'black !important',
          },
          '& svg': {
            color: 'black !important',
          },
          '& label.Mui-focused': {
            color: 'black !important',
          },
          '& .Mui-disabled': {
            color: 'black !important',
            WebkitTextFillColor: 'black !important',
          },
          '& .MuiInput-underline:after': {
            borderBottomColor: 'black !important',
          },
          '& .MuiFilledInput-underline:after': {
            borderBottomColor: 'black !important',
          },
        },
        paper: {
          backgroundColor: '#fff',
        },
        noOptions: {
          color: '#fff !important',
        },
      },
    },
  },
  palette: {
    background: {
      default: '#fff',
      paper: '#fff',
    },
  },
})

interface IProps {
  children: JSX.Element
}

export const MUIThemeProvider: FC<IProps> = ({ children }) => {
  const { isLightMode } = useContext(AppConfigContext)

  if (isLightMode) {
    return <ThemeProvider theme={LightTheme}>{children}</ThemeProvider>
  }

  return <ThemeProvider theme={DarkTheme}>{children}</ThemeProvider>
}
