import { FC, useContext, useEffect, useState } from 'react'
import { Box, Typography } from '@mui/material'
import NodeIcons from '../../components/shared/NodeIcons'
import NodeHandle from '../../components/shared/Handle'
import DraggableButton from '../../components/shared/DraggableButton'
import { PlaybookContext } from '../../contexts/playbooks.context'
import { AppConfigContext } from '../../contexts/app-config.context'
import { NodeTypes } from '../../types/node.type'
import { NodeLabels } from '../../utils/constants'

import 'mapbox-gl/dist/mapbox-gl.css'
import styles from './styles.module.scss'

interface IProps {
  data: {
    label: NodeTypes
    nodeId: number
  }
}

const TextNode: FC<IProps> = ({ data }) => {
  const [isHighlighted, setIsHighlighted] = useState(false)

  const { isLightMode } = useContext(AppConfigContext)
  const { playbook, nodes } = useContext(PlaybookContext)

  useEffect(() => {
    const node = nodes.find((node) => node.selected && +node.id === data.nodeId)

    if (node) {
      setIsHighlighted(true)
    } else {
      setIsHighlighted(false)
    }
  }, [nodes])

  return (
    <div
      id={`${data.nodeId}`}
      className={styles.node}
      style={
        isHighlighted
          ? {
              border: '1px solid white',
              backgroundColor: isLightMode ? '#fff' : '#13222e',
              boxShadow: isLightMode ? '0 0 10px black' : '0 0 10px #fff',
            }
          : {
              backgroundColor: isLightMode ? '#fff' : '#13222e',
            }
      }
    >
      <DraggableButton
        backgroundColor={'white'}
        isLightMode={isLightMode}
        isSelected={isHighlighted}
      />
      <div className={styles.content + ' nodrag'}>
        <Box className={styles.header}>
          <NodeIcons nodeType={data.label} />
          <Typography id="nodeIdentifier" className={styles.text}>
            {NodeLabels[data.label]}
          </Typography>
        </Box>
        {playbook && <NodeHandle direction={playbook.direction} />}
      </div>
    </div>
  )
}

export default TextNode
