import { useContext, useState } from 'react'
import dayjs from 'dayjs'
import { Box } from '@mui/material'
import { IDevice } from '../../../../types/equipment.type'
import DeviceModal from '../../Modals/DeviceModal'
import TableComponent from '../../../shared/Table'
import { DevicesConfigs } from '../../../../utils/constants'
import { SpectrumManagerContext } from '../../../../contexts/spectrum.context'
import {
  createDevice,
  deleteDevice,
  updateDevice,
} from '../../../../services/spectrum-manager.service'
import { useToast } from '../../../../contexts/toast.context'
import { AppConfigContext } from '../../../../contexts/app-config.context'

import styles from './styles.module.scss'

export const Devices = () => {
  const [device, setDevice] = useState<IDevice | null>(null)
  const [isEditing, setIsEditing] = useState(false)
  const [isCreating, setIsCreating] = useState(false)

  const { showToast } = useToast()
  const { isLightMode } = useContext(AppConfigContext)
  const { devices, setDevices } = useContext(SpectrumManagerContext)

  const handleDeviceRemove = async (id: number) => {
    const data = await deleteDevice(id)

    if (data.success) {
      setDevices(devices.filter((device) => device.id !== id))
      showToast('success', 'Device is deleted successfully')
    }
  }

  const handleCreate = async () => {
    if (device) {
      const data = await createDevice(device)

      if (data.data) {
        setDevices([...devices, data.data])
        showToast('success', 'Device is created successfully')
        setDevice(data.data)
        setIsEditing(true)
        setIsCreating(false)
      }
    }
  }

  const handleChange = async (
    key: string,
    value: string | number | dayjs.Dayjs | null,
  ) => {
    const changedDevice = device ? device : ({} as IDevice)

    setDevice({ ...changedDevice, [key]: value })
  }

  const onDeviceCreate = () => {
    setIsCreating(true)
    setDevice({} as IDevice)
  }

  const handleView = (item: IDevice) => {
    setIsEditing(true)
    setDevice(item)
  }

  const handleSave = async () => {
    if (isCreating) {
      handleCreate()
      return
    }

    if (device) {
      const data = await updateDevice(device.id, device)

      if (data.data) {
        const parsedDevice = {
          ...data.data,
          last_updated: dayjs(data.data.last_updated).utc(true),
        }

        setDevices((prev) =>
          prev.map((device) =>
            data.data && device.id === data.data.id ? parsedDevice : device,
          ),
        )
        showToast('success', 'Device is updated successfully')
        setDevice(null)
        setIsEditing(false)
        setIsCreating(false)
      }
    }
  }

  return (
    <Box className={styles.devices}>
      <TableComponent
        isLightMode={isLightMode}
        data={devices}
        configs={DevicesConfigs}
        handleCreate={onDeviceCreate}
        handleView={handleView}
        handleDelete={handleDeviceRemove}
      />
      {device && (
        <DeviceModal
          device={device}
          open={isCreating || isEditing}
          isCreating={isCreating}
          handleSave={handleSave}
          fields={DevicesConfigs.items}
          onClose={() => {
            setDevice(null)
            setIsEditing(false)
            setIsCreating(false)
          }}
          handleChange={handleChange}
        />
      )}
    </Box>
  )
}
