import { FC, useContext } from 'react'
import { Box } from '@mui/material'
import SendIcon from '@mui/icons-material/Send'
import EmailIcon from '@mui/icons-material/Email'
import PublicIcon from '@mui/icons-material/Public'
import SubjectIcon from '@mui/icons-material/Subject'
import Grid3x3Icon from '@mui/icons-material/Grid3x3'
import SettingsIcon from '@mui/icons-material/Settings'
import DonutSmallIcon from '@mui/icons-material/DonutSmall'
import EmojiFlagsIcon from '@mui/icons-material/EmojiFlags'
import SignalWifi2BarIcon from '@mui/icons-material/SignalWifi2Bar'
import SignalWifi1BarIcon from '@mui/icons-material/SignalWifi1Bar'
import SignalWifi4BarIcon from '@mui/icons-material/SignalWifi4Bar'
import SignalWifi3BarIcon from '@mui/icons-material/SignalWifi3Bar'
import KeyboardCommandKeyIcon from '@mui/icons-material/KeyboardCommandKey'
import SignalCellular1BarIcon from '@mui/icons-material/SignalCellular1Bar'
import SignalCellular2BarIcon from '@mui/icons-material/SignalCellular2Bar'
import SignalCellular3BarIcon from '@mui/icons-material/SignalCellular3Bar'
import SignalCellular4BarIcon from '@mui/icons-material/SignalCellular4Bar'
import SignalCellularAlt2BarOutlinedIcon from '@mui/icons-material/SignalCellularAlt2BarOutlined'
import SignalCellularAlt1BarOutlinedIcon from '@mui/icons-material/SignalCellularAlt1BarOutlined'
import SignalCellularAlt3BarOutlinedIcon from '@mui/icons-material/SignalCellularAltOutlined'
import AnimatedIcon from '../AnimatedIcon'
import { NodeTypes } from '../../../types/node.type'

import styles from './styles.module.scss'

interface IProps {
  nodeType: NodeTypes
}

const NodeIcons: FC<IProps> = ({ nodeType }) => {
  const nodeIcon = () => {
    switch (nodeType) {
      case NodeTypes.EMAIL:
        return (
          <EmailIcon
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.BUTTONS:
        return (
          <KeyboardCommandKeyIcon
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.REQUEST_ID:
        return (
          <Grid3x3Icon
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.MAP:
        return (
          <PublicIcon
            className={styles.animatedIcon}
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
              transition: 'border-radius 0.5s ease-in-out',
            }}
          />
        )
      case NodeTypes.POST_REQUEST:
        return (
          <SendIcon
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.SwitchFrequency:
        return (
          <AnimatedIcon
            isAnimated={true}
            icons={[
              <SignalCellular4BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalCellular1BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalCellular2BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalCellular3BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
            ]}
          />
        )
      case NodeTypes.SFAF:
        return (
          <SubjectIcon
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.TurnOffNetwork:
        return (
          <AnimatedIcon
            isAnimated={true}
            icons={[
              <SignalWifi4BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalWifi1BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalWifi2BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalWifi3BarIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
            ]}
          />
        )
      case NodeTypes.ModifyBandwidth:
        return (
          <AnimatedIcon
            isAnimated={true}
            icons={[
              <SignalCellularAlt1BarOutlinedIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalCellularAlt2BarOutlinedIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
              <SignalCellularAlt3BarOutlinedIcon
                sx={{
                  color: '#ff9c00',
                  width: 40,
                  height: 40,
                }}
              />,
            ]}
          />
        )
      case NodeTypes.ModifyQualityOfService:
        return (
          <SettingsIcon
            className={styles.animatedIcon}
            sx={{
              color: '#ff9c00',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.PLAYBOOK:
        return (
          <EmojiFlagsIcon
            sx={{
              color: 'white',
              width: 40,
              height: 40,
            }}
          />
        )
      case NodeTypes.SLIDER:
        return (
          <DonutSmallIcon
            className={styles.animatedIcon}
            sx={{ color: '#ff9c00', width: 40, height: 40 }}
          />
        )
      default:
        break
    }
  }

  return <Box className={styles.nodeIcons}>{nodeIcon()}</Box>
}

export default NodeIcons
