import {
  Autocomplete,
  Box,
  IconButton,
  TextField,
  Typography,
} from '@mui/material'
import cn from 'classnames'
import { useState } from 'react'
import { GeoJsonObject } from 'geojson'
import { useEmitters } from '../../../contexts/emitters.context'
import { IFrequencyAssignment, IExercise } from '../../../types/exercise.type'
import FrequencyRangeInput from '../../../components/shared/FrequencyFilters/FrequencyInputs'
import FrequencyPicker from '../../../components/shared/FrequencyFilters/FrequencyPicker'
import Input from '../../../components/ui/Input'
import DateRangePicker from '../../../components/ui/DateRangePicker'
import MultiRangeSlider from '../../../components/ui/MultiRangeSlider'
import Select from '../../../components/ui/Select'
import KMZUploader from './kmz-uploader'
import ArrowButton from '@mui/icons-material/ArrowCircleRightOutlined'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined'
import RemoveButton from '@mui/icons-material/DeleteOutline'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import scss from './styles.module.scss'

interface IProps {
  onKmlUpload: (dataSource: GeoJsonObject) => void
}

function getKeys<T>() {
  return <K extends keyof T>(keys: K[]) => keys
}

const exerciseKeys = getKeys<IFrequencyAssignment>()([
  'exercise_request_frequency_id',
  'exercise_request_frequency_created_at',
  'exercise_request_frequency_supplementary_details',
  'exercise_request_frequency_single_id',
  'exercise_request_frequency_single_start_indicator',
  'exercise_request_frequency_single_start_freq',
  'exercise_request_frequency_single_end_indicator',
  'exercise_request_frequency_single_end_freq',
  'exercise_request_location_longitude_deg',
  'exercise_request_location_longitude_min',
  'exercise_request_location_longitude_sec',
  'exercise_request_location_longitude_ew',
  'exercise_request_location_latitude_deg',
  'exercise_request_location_latitude_min',
  'exercise_request_location_latitude_sec',
  'exercise_request_location_latitude_ns',
  'exercise_request_location_radius',
  'exercise_request_location_transmitter_location',
  'state_region_type_name',
  'state_region_type_code',
  'major_function_511_name',
  'intermediate_function_512_name',
  'detailed_function_513_name',
  'location_afc',
  'location_afc_code',
  'location_afc_coord_note',
  'number_of_frequencies',
  'exercise_request_assigned_frequency_list_serial_number',
  'f_114',
  'id_override',
  'exercise_name',
])

const WhiteButton = styled(Button)({ backgroundColor: 'white' })

const SideBar: React.FC<IProps> = ({ onKmlUpload }) => {
  const {
    filters,
    frequencyRange,
    startDate,
    endDate,
    areUploadsVisible,
    exercisesToFilter,
    getExercises,
    clearFilters,
    handleFilterChange,
    handleFilterRemove,
    handleDateRangeChange,
    handleFrequencyRangechange,
    toggleUploadVisibility,
  } = useEmitters()
  const [isOpen, setIsOpen] = useState(false)
  const [frequencyLimits, setFrequencyLimits] = useState({
    min: 0,
    max: 10000,
  })

  const handleInputChange = (
    index: number,
    key: keyof IFrequencyAssignment,
    value: string,
  ) => {
    const parsedValue = isNaN(Number(value)) ? value : Number(value)
    handleFilterChange(index, key, parsedValue)
  }

  return (
    <div className={`${scss.sidebar} sub-container`}>
      <div className={scss.container}>
        <div className={cn(scss.box, { [scss.closed]: !isOpen })}>
          <h4>Filter by:</h4>

          <div className={scss.frequencies}>
            <p>Frequency:</p>
            <div className={scss.frequencySlider}>
              <MultiRangeSlider
                min={frequencyLimits.min}
                max={frequencyLimits.max}
                values={
                  frequencyRange
                    ? [frequencyRange.min, frequencyRange.max]
                    : undefined
                }
                onChange={handleFrequencyRangechange}
              />
            </div>
            <FrequencyRangeInput
              values={frequencyRange}
              onChange={handleFrequencyRangechange}
            />
            <FrequencyPicker
              values={frequencyLimits}
              onChange={(values) => {
                setFrequencyLimits(values)
                handleFrequencyRangechange(values)
              }}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'column', gap: 12 }}>
            <p>Date:</p>
            {!!exercisesToFilter.length && (
              <Autocomplete
                fullWidth
                options={exercisesToFilter}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Exercise to filter date"
                  />
                )}
                onChange={(_, newValue: IExercise | null) => {
                  if (newValue) {
                    // We have to play some sketchy type games here
                    // because IExercise is declared with Dates,
                    // but axios only gives us the JSON strings
                    // and doesn't know anything about converting them to Dates.
                    // Since we share getAllExercises with the dashboard,
                    // I'm not sure if it's safe to change the type
                    // or the exercise-request-form service.
                    handleDateRangeChange(
                      (newValue.start_date as unknown as string).split('T')[0],
                      (newValue.end_date as unknown as string).split('T')[0],
                    )
                  }
                }}
              />
            )}
            <DateRangePicker
              initialStartDate={startDate || undefined}
              initialEndDate={endDate || undefined}
              onDateRangeChange={(startDate, endDate) =>
                handleDateRangeChange(
                  startDate ? startDate.toISOString().split('T')[0] : null,
                  endDate ? endDate.toISOString().split('T')[0] : null,
                )
              }
            />
          </div>

          <div>
            <div className={scss.label}>
              <Typography>Custom field:</Typography>
              {filters.length <= 10 && (
                <IconButton
                  onClick={() =>
                    handleFilterChange(
                      filters.length,
                      'exercise_request_frequency_id',
                      '',
                    )
                  }
                >
                  <AddCircleOutlineOutlinedIcon />
                </IconButton>
              )}
            </div>
            <div className={scss.customFilters}>
              {filters.map((filter, index) => (
                <div key={index} className={scss.filter}>
                  <div style={{ width: '50%' }}>
                    <Select
                      value={filter.key}
                      onChange={(e) =>
                        handleFilterChange(
                          index,
                          e.target.value as keyof IFrequencyAssignment,
                          filter.value,
                        )
                      }
                      options={exerciseKeys.map((key) => ({
                        label: key,
                        value: key,
                      }))}
                    />
                  </div>
                  <Input
                    type="text"
                    value={String(filter.value)}
                    onChange={(e) =>
                      handleInputChange(index, filter.key, e.target.value)
                    }
                  />
                  <IconButton onClick={() => handleFilterRemove(index)}>
                    <RemoveButton />
                  </IconButton>
                </div>
              ))}
            </div>
            <div style={{ display: 'flex', gap: 12, marginTop: 12 }}>
              <Button variant="outlined" onClick={getExercises}>
                Filter
              </Button>
              <Button variant="outlined" onClick={clearFilters}>
                Clear
              </Button>
            </div>
          </div>
          <Box
            className={cn(scss.arrowButton, { [scss.closed]: !isOpen })}
            onClick={() => setIsOpen((prev) => !prev)}
          >
            <ArrowButton className={scss.icon} />
          </Box>
          <div
            className={scss.heatmapButton}
            style={{ left: isOpen ? 458 : 58 }}
          >
            <WhiteButton variant="outlined" onClick={toggleUploadVisibility}>
              {areUploadsVisible ? 'Hide' : 'Show'} uploads
            </WhiteButton>
          </div>

          <div style={{ marginTop: 'auto' }}>
            <KMZUploader onKMLLoaded={onKmlUpload} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
