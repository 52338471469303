import { useEffect, useState } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Box, TextField, Typography } from '@mui/material'
import NodeItem from '../NodeItem/index'
import { NewNodes } from '../../../../utils/constants'

import styles from './styles.module.scss'

const DragNDrop = () => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredNewNodes, setFilteredNewNodes] = useState(NewNodes)

  useEffect(() => {
    setFilteredNewNodes(
      NewNodes.filter(({ name }) =>
        name.toLowerCase().includes(searchValue.toLocaleLowerCase()),
      ),
    )
  }, [searchValue])

  return (
    <>
      <TextField
        data-testid="input"
        value={searchValue}
        label="Search Nodes"
        className={styles.search}
        onChange={(evt) => setSearchValue(evt.target.value)}
      />
      <Typography>New Nodes Drag&Drop</Typography>
      <DndProvider backend={HTML5Backend}>
        <Box className={styles.dragNDrop}>
          {filteredNewNodes.map((newNode) => (
            <NodeItem
              key={newNode.type}
              name={newNode.name}
              type={newNode.type}
              description={newNode.description}
              icon={<newNode.icon sx={{ color: '#EA7C18' }} />}
            />
          ))}
        </Box>
      </DndProvider>
    </>
  )
}

export default DragNDrop
