import { Dispatch, FC, SetStateAction, useMemo, useRef } from 'react'
import { Marker } from 'react-leaflet'
import { LatLngTuple } from 'leaflet'

interface IProps {
  center: LatLngTuple
  children: JSX.Element
  draggable: boolean
  handleClick: () => void
  setDragging: Dispatch<SetStateAction<boolean>>
  handlePositionChange: (value: { lat: number; lng: number }) => void
}

const DraggableMarker: FC<IProps> = ({
  center,
  children,
  draggable,
  setDragging,
  handleClick,
  handlePositionChange,
}) => {
  const markerRef = useRef<any>(null)

  const eventHandlers = useMemo(
    () => ({
      click: handleClick,
      dragend() {
        const marker = markerRef.current
        if (marker != null) {
          handlePositionChange(marker.getLatLng())
        }
      },
      mousedown: () => {
        setDragging(true)
      },
      mouseup: () => {
        setDragging(false)
      },
    }),
    [],
  )

  return (
    <Marker
      draggable={draggable}
      eventHandlers={eventHandlers}
      position={center}
      ref={markerRef}
    >
      {children}
    </Marker>
  )
}

export default DraggableMarker
