import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from 'react'
import { Box, Button, Checkbox, TextField, Typography } from '@mui/material'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import ConfirmationModal from '../../Modals/ConfirmationModal'
import { IExerciseRequest } from '../../../../types/exercise.type'
import { IClassificationType } from '../../../../types/classifications.type'
import { ExerciseRequestContext } from '../../../../contexts/exercise-request.context'
import {
  IOperatingUnit,
  IOperatingSubUnit,
} from '../../../../types/operating-unit.types'
import {
  getAllOperatingUnits,
  updateExerciseRequest,
  getAllOperatingSubUnits,
  deleteExerciseRequestById,
  getAllClassificationTypes,
} from '../../../../services/exercise-request-form.service'
import { useToast } from '../../../../contexts/toast.context'
import { SelectWithSearch } from '../../../shared/SearchSelect'

import styles from './styles.module.scss'

interface IProps {
  setValue: Dispatch<SetStateAction<number>>
  selectedExerciseRequest: IExerciseRequest
}

const GeneralSection: FC<IProps> = ({ setValue, selectedExerciseRequest }) => {
  const { exercises, setExerciseRequests, setSelectedExerciseRequest } =
    useContext(ExerciseRequestContext)

  const [request, setRequest] = useState<IExerciseRequest | null>(null)
  const [manager, setManager] = useState('')
  const [isDelete, setIsDelete] = useState(false)
  const [subAccounts, setSubAccounts] = useState<IOperatingSubUnit[]>([])
  const [isCustomDate, setIsCustomDate] = useState(false)
  const [operatingUnits, setOperatingUnits] = useState<IOperatingUnit[]>([])
  const [classificationTypes, setClassificationTypes] = useState<
    IClassificationType[]
  >([])

  const { showToast } = useToast()

  const handleClose = () => {
    setIsDelete(false)
  }

  const handleSave = async (shouldNavigate?: boolean) => {
    if (request && request.operating_unit_id) {
      const data = await updateExerciseRequest(
        selectedExerciseRequest.id,
        request,
      )

      if (data.success && data.data) {
        const parsedRequest = {
          ...data.data,
          start_date: dayjs(data.data.start_date).utc(true),
          end_date: dayjs(data.data.end_date).utc(true),
        }

        setExerciseRequests((prev) =>
          prev.map((elem) =>
            elem.id === selectedExerciseRequest.id && data.data
              ? parsedRequest
              : elem,
          ),
        )
        setSelectedExerciseRequest(parsedRequest)
        handleClose()
        showToast('success', 'Exercise Request successfully updated')

        if (shouldNavigate) {
          setValue(1)
        }
      } else {
        showToast('error', 'Something went wrong, please try again')
      }
    } else {
      showToast('error', 'Please fill out all required fields')
    }
  }

  const handleDelete = async () => {
    const data = await deleteExerciseRequestById(selectedExerciseRequest.id)

    if (data.success) {
      setExerciseRequests((prev) =>
        prev.filter((elem) => elem.id !== selectedExerciseRequest.id),
      )
      setSelectedExerciseRequest(null)
      showToast('success', data.message)
      handleClose()
    }
  }

  const handleChange = (key: string, value?: string | number | Dayjs) => {
    if (request) {
      setRequest({ ...request, [key]: value })
    }
  }

  const getOperatingUnits = async () => {
    const data = await getAllOperatingUnits()

    if (data.success && data.data) {
      setOperatingUnits(data.data)
    }
  }

  const handleNumericChange = (attr: string, value: string) => {
    if (/^\d*$/.test(value)) {
      handleChange(attr, value)
    }
  }

  const getOperatingSubUnits = async () => {
    if (request) {
      const data = await getAllOperatingSubUnits(request?.operating_unit_id)

      if (data.success && data.data) {
        setSubAccounts(data.data)
      }
    }
  }

  const getClassificationTypes = async () => {
    const data = await getAllClassificationTypes()

    if (data.success && data.data) {
      setClassificationTypes(data.data)
    }
  }

  useEffect(() => {
    if (request?.operating_unit_id) {
      const chosenOperatingUnit = operatingUnits.find(
        (elem) => elem.id === request.operating_unit_id,
      )

      if (chosenOperatingUnit) {
        setManager(chosenOperatingUnit.manager)
      }

      getOperatingSubUnits()
    }
  }, [request?.operating_unit_id])

  useEffect(() => {
    setRequest(selectedExerciseRequest)

    const isCustomDate =
      selectedExerciseRequest.start_date?.isValid() &&
      selectedExerciseRequest.end_date?.isValid()

    setIsCustomDate(!!isCustomDate)
  }, [selectedExerciseRequest])

  useEffect(() => {
    getOperatingUnits()
    getClassificationTypes()
  }, [])

  return (
    <Box className={styles.generalSection}>
      <Box className={styles.actions}>
        <Button
          className={styles.saveBtn}
          variant="outlined"
          onClick={() => handleSave()}
        >
          Save
        </Button>
        <Button
          className={styles.deleteBtn}
          variant="outlined"
          data-testid="deleteButton"
          onClick={() => setIsDelete(true)}
        >
          Delete
        </Button>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Requestor</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Name<span className={styles.asterisk}>*</span>:
            </Typography>
            <TextField
              value={request?.username || ''}
              onChange={(evt) => handleChange('username', evt.target.value)}
              className={styles.field}
              placeholder="Enter Value"
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Phone<span className={styles.asterisk}>*</span>:
            </Typography>
            <TextField
              id="phoneNumber"
              className={styles.field}
              value={request?.user_phone || ''}
              inputMode="numeric"
              type="text"
              onChange={(evt) =>
                handleNumericChange('user_phone', evt.target.value)
              }
              placeholder="Enter Value"
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Request For</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Exercise<span className={styles.asterisk}>*</span>:
            </Typography>
            <SelectWithSearch
              width={300}
              value={exercises
                .map((exercise) => ({
                  label: exercise.name,
                  value: exercise.id,
                }))
                .find((elem) => elem.value === request?.exercise_id)}
              label="Exercise"
              handleChange={(value) =>
                handleChange('exercise_id', Number(value))
              }
              options={exercises.map((exercise) => ({
                value: exercise.id,
                label: exercise.name,
              }))}
            />
          </Box>
          <Box>
            <Box className={styles.checkboxContainer}>
              <Typography className={styles.label}>
                Add custom date range
              </Typography>
              <Checkbox
                data-testid="custom-date-checkbox"
                checked={isCustomDate}
                onClick={() => setIsCustomDate(!isCustomDate)}
              />
            </Box>
            {isCustomDate && (
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Box className={styles.datePicker}>
                  <DatePicker
                    label="Start Date"
                    value={request?.start_date}
                    onChange={(newValue) =>
                      handleChange('start_date', dayjs(newValue).utc(true))
                    }
                  />
                  <DatePicker
                    label="End Date"
                    value={request?.end_date}
                    onChange={(newValue) =>
                      handleChange('end_date', dayjs(newValue).utc(true))
                    }
                  />
                </Box>
              </LocalizationProvider>
            )}
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Request Name:</Typography>
            <TextField
              value={request?.name || ''}
              className={styles.field}
              onChange={(evt) => handleChange('name', evt.target.value)}
              placeholder="Enter Value"
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>
              Operating Unit
              <span className={styles.asterisk}>*</span>:
            </Typography>
            <SelectWithSearch
              width={300}
              value={operatingUnits
                .map((operatingUnit) => ({
                  label: operatingUnit.unit_name,
                  value: operatingUnit.id,
                }))
                .find((elem) => elem.value === request?.operating_unit_id)}
              label="Unit"
              handleChange={(value) =>
                handleChange('operating_unit_id', Number(value))
              }
              options={operatingUnits.map((operatingUnit) => ({
                value: operatingUnit.id,
                label: operatingUnit.unit_name,
              }))}
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Subaccount:</Typography>
            <SelectWithSearch
              width={300}
              value={subAccounts
                .map((unit) => ({
                  label: unit.name,
                  value: unit.id,
                }))
                .find((elem) => elem.value === request?.operating_sub_unit_id)}
              label="Sub Account"
              handleChange={(value) =>
                handleChange('operating_sub_unit_id', Number(value))
              }
              options={subAccounts.map((unit) => ({
                value: unit.id,
                label: unit.name,
              }))}
            />
          </Box>
          <Box className={styles.fieldContainer}>
            <Typography className={styles.label}>Manager:</Typography>
            <TextField
              value={manager || ''}
              label="Manager"
              className={styles.field}
              disabled
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.sectionContainer}>
        <Box className={styles.titleContainer}>
          <Typography className={styles.title}>Releasability</Typography>
        </Box>
        <Box className={styles.section}>
          <Box className={styles.releasabilityContainer}>
            <SelectWithSearch
              value={classificationTypes
                .map((classificationType) => ({
                  label: classificationType.name,
                  value: classificationType.id,
                }))
                .find((elem) => elem.value === request?.classification_type_id)}
              label="Classification Type"
              handleChange={(value) =>
                handleChange('classification_type_id', Number(value))
              }
              options={classificationTypes.map((classificationType) => ({
                value: classificationType.id,
                label: classificationType.name,
              }))}
            />
            <Typography>
              Not releasable outside the US Government IAW Section 552 (b)(1) of
              Title 5 of the US Code.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Button
        variant="outlined"
        onClick={() => {
          setValue(1)
          handleSave()
        }}
      >
        Next
      </Button>
      {isDelete && (
        <ConfirmationModal
          onClose={handleClose}
          color={'error'}
          onConfirm={handleDelete}
          btnText={'Delete'}
          text={'Are you sure you want to delete this exercise request'}
        />
      )}
    </Box>
  )
}

export default GeneralSection
