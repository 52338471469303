import { FC } from 'react'
import { Box } from '@mui/material'
import { useKeycloak } from '@react-keycloak/web'
import { Navigate, useRoutes } from 'react-router-dom'
import EmittersProvider from '../contexts/emitters.context'
import Header from '../components/features/Header'
import Login from '../pages/login'
import Playbook from '../pages/playbook'
import Map from '../pages/map'
import PlaybooksDashboard from '../pages/PlaybooksDashboard'
import ExerciseRequestFormPage from '../pages/ExecrciseRequestForm'
import SpectrumManagerDashboard from '../pages/SpectrumManagerDashboard'
import Requests from '../components/features/SpectrumManagerDashboard/Requests'
import { Devices } from '../components/features/SpectrumManagerDashboard/Devices'
import { Licenses } from '../components/features/SpectrumManagerDashboard/Licenses'
import { Platforms } from '../components/features/SpectrumManagerDashboard/Platforms'
import { Exercises } from '../components/features/SpectrumManagerDashboard/Exercises'
import ArcDiagramIframe from '../components/features/SpectrumManagerDashboard/ArcDiagrams'
import { OperatingUnits } from '../components/features/SpectrumManagerDashboard/OperatingUnits'
import { LocationTemplates } from '../components/features/SpectrumManagerDashboard/LocationTemplates'

interface IProps {
  children: JSX.Element
}

const Layout: FC<IProps> = ({ children }) => {
  return (
    //TODO: Transfer the styles to the global CSS file after it's merged.
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
      <Header />
      {children}
    </Box>
  )
}

const ProtectedRoute: FC<{ element: JSX.Element; type?: string }> = ({
  element,
  type,
}) => {
  const { keycloak } = useKeycloak()

  if (type === 'login') {
    return keycloak.authenticated ? (
      <Navigate to="/frequency-request-form" />
    ) : (
      element
    )
  }

  return keycloak.authenticated ? element : <Navigate to="/login" />
}

const RoutesComponent = () => {
  const routes = useRoutes([
    {
      path: '/:playbookId',
      element: (
        <ProtectedRoute
          element={
            <Layout>
              <Playbook />
            </Layout>
          }
        />
      ),
    },
    {
      path: '/map',
      // TODO: Move the Provider somewhere else?:
      element: (
        <ProtectedRoute
          element={
            <EmittersProvider>
              <Layout>
                <Map />
              </Layout>
            </EmittersProvider>
          }
        />
      ),
    },
    {
      path: '/playbooks',
      element: (
        <ProtectedRoute
          element={
            <Layout>
              <PlaybooksDashboard />
            </Layout>
          }
        />
      ),
    },
    {
      path: '/login',
      element: <ProtectedRoute type="login" element={<Login />} />,
    },
    {
      path: '/',
      element: <Navigate to="/frequency-request-form" replace />,
    },
    {
      path: '/spectrum-manager-dashboard',
      element: (
        <ProtectedRoute
          element={
            <Layout>
              <SpectrumManagerDashboard />
            </Layout>
          }
        />
      ),
      children: [
        { path: 'devices', element: <Devices /> },
        { path: 'requests', element: <Requests /> },
        { path: 'licenses', element: <Licenses /> },
        { path: 'exercises', element: <Exercises /> },
        { path: 'platforms', element: <Platforms /> },
        { path: 'units', element: <OperatingUnits /> },
        { path: 'locations', element: <LocationTemplates /> },
        { path: 'links-diagram', element: <ArcDiagramIframe /> },
        { index: true, element: <Navigate to="requests" replace /> },
      ],
    },
    {
      path: '/frequency-request-form',
      element: (
        <ProtectedRoute
          element={
            <Layout>
              <ExerciseRequestFormPage />
            </Layout>
          }
        />
      ),
    },
  ])

  return routes
}

export default RoutesComponent
