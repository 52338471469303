import { useContext, useState } from 'react'
import { Box } from '@mui/material'
import PlaybooksTable from '../../components/features/playbooks/PlaybookTable'
import DashboardToolbar from '../../components/features/playbooks/DashboardToolbar'
import PlaybookCreateModal from '../../components/features/Modals/PlaybookCreateModa'
import { PlaybookContext } from '../../contexts/playbooks.context'
import { AppConfigContext } from '../../contexts/app-config.context'

import styles from './styles.module.scss'

const PlaybooksDashboard = () => {
  const [isOpen, setIsOpen] = useState(false)

  const { getPlaybooks } = useContext(PlaybookContext)

  const handleSearch = (search: string) => {
    getPlaybooks(search)
  }

  return (
    <Box className={`${styles.dashboard} main-container`}>
      <DashboardToolbar
        btnText="Create Playbook"
        handleSearch={handleSearch}
        setIsOpen={setIsOpen}
      />
      <PlaybooksTable />
      <PlaybookCreateModal open={isOpen} onClose={() => setIsOpen(false)} />
    </Box>
  )
}

export default PlaybooksDashboard
