import { useContext } from 'react'
import { useKeycloak } from '@react-keycloak/web'
import { Box, Button, Typography } from '@mui/material'
import SPEX_logo from '../../assets/images/spex_logo.png'
import OktaBlack from '../../assets/images/okta-black.png'
import OktaWhite from '../../assets/images/okta-white.png'
import { AppConfigContext } from '../../contexts/app-config.context'

import styles from './styles.module.scss'

const Login = () => {
  const { isLightMode } = useContext(AppConfigContext)
  const { keycloak } = useKeycloak()

  return (
    <Box className={styles.login}>
      <img src={SPEX_logo} className={styles.spexLogo} />
      <Box className={styles.btnContainer}>
        <Button className={styles.loginBtn} onClick={() => keycloak.login()}>
          <Typography>Connect</Typography>
          <img
            className={styles.auth0Logo}
            src={isLightMode ? OktaBlack : OktaWhite}
          />
        </Button>
      </Box>
    </Box>
  )
}

export default Login
